/* eslint-disable max-len */
export const RESOURCE_BUNDLES_PT = {
  "Submit Form": "Enviar Formulário",
  "Changes Applied Successfully": "Alterações Aplicadas com Sucesso",
  "Filter Deleted Successfully": "Filtro Excluído com Sucesso",
  "Save Filter": "Salvar Filtro",
  "Filter Name": "Nome do Filtro",
  "Create new filter": "Criar novo filtro",
  "Definition Key": "Chave de Definição",
  "Permission": "Permissão",
  "Enter your text here": "Digite seu texto aqui",
  "Accessible for all users": "Acessível para todos os usuários",
  "Private (Only You)": "Privado (Apenas Você)",
  "Specific Group": "Grupo Específico",
  "Select Elements": "Selecionar Elementos",
  " Task Attributes Selected": " Atributos da Tarefa Selecionados",
  "Delete Filter": "Excluir Filtro",
  "Add Value": "Adicionar Valor",
  "Show only current user assigned task": "Mostrar apenas tarefas atribuídas ao usuário atual",
  "Show task based on logged user roles": "Mostrar tarefas com base nos papéis do usuário conectado",
  "Create Filter": "Criar Filtro",
  "Task Attributes": "Atributos da Tarefa",
  "Only selected task attributes will be available on task list view": "Apenas os atributos selecionados da tarefa estarão disponíveis na visualização da lista de tarefas",
  "Show undefined variables": "Mostrar variáveis indefinidas",
  "Label": "Rótulo",
  "Variables": "Variáveis",
  "Task Title": "Título da Tarefa",
  "List View": "Visualização em Lista",
  "Card View": "Visualização em Cartões",
  "Create New Filter": "Criar Novo Filtro",
  "Sort by": "Ordenar por",
  "Collapse All": "Recolher Tudo",
  "Expand All": "Expandir Tudo",
  "Application Id": "ID da Aplicação",
  "Back to task": "Voltar para a tarefa",
  "Filters": "Filtros",
  "Assignee": "Responsável",
  "Select a user": "Selecionar um usuário",
  "Create DMN": "Criar DMN",
  "Create Process": "Criar Processo",
  "Edit DMN": "Editar DMN",
  "More options": "Mais opções",
  "Form Description": "Descrição do Formulário",
  "Saved to Submissions/Drafts": "Salvo em Submissões/Rascunhos",
  "Unfinished submissions will be saved to Submissions/Drafts.": "Submissões inacabadas serão salvas em Submissões/Rascunhos.",
  "Edit Process": "Editar Processo",
  "Add filter name": "Adicionar nome do filtro",
  "This section is aimed to set the parameters used to filter the tasks": "Esta seção tem como objetivo definir os parâmetros usados para filtrar as tarefas",
  "This section is aimed to set read permissions for the filter": "Esta seção tem como objetivo definir permissões de leitura para o filtro",
  "This section is aimed to set select task attributes that will be visible in the task list view": "Esta seção tem como objetivo definir os atributos da tarefa que serão visíveis na visualização da lista de tarefas",
  "Task Attribute": "Atributo da Tarefa",
  "You can define variables shown in the list": "Você pode definir variáveis mostradas na lista",
  "results": "Resultados",
  "Enter role name": "Digite o nome da função",
  "Public": "Público",
  "View Submitted Form": "Ver Formulário Enviado",
  "View Draft Details": "Ver Detalhes do Rascunho",
  "Edit Draft": "Editar Rascunho",
  "Draft Title": "Título do Rascunho",
  "Filter": "Filtro",
  "Advanced Options": "Opções Avançadas",
  "Enter the path name": "Digite o nome do caminho",
  "Search by form title": "Buscar por título de formulário",
  "Associate Workflow": "Associar Fluxo de Trabalho",
  "Search by workflow name": "Buscar por nome de fluxo de trabalho",
  "View Details": "Ver Detalhes",
  "Visibility": "Visibilidade",
  "Form Title": "Título do Formulário",
  "Access restricted by its designer..!": "Acesso restrito pelo designer..!",
  "and you will no longer have the access to this form in bundles": "e você não terá mais acesso a este formulário em pacotes",
  "You will no longer have the access to this form in bundles": "Você não terá mais acesso a este formulário em pacotes",
  "Bad Request": "Pedido ruim",
  "Select the form to be displayed based on the  above criteria": "Selecione o formulário a ser exibido com base nos critérios acima.",
  "Create the criteria using the API key of any form component and a conditional notation. For example, 'age > 18' where 'age' is the API key of a form component": "Crie os critérios usando a chave API de qualquer componente de formulário e uma notação condicional. Por exemplo, 'age > 18', onde 'age' é a chave API de um componente de formulário.",
  "Select form version": "Selecione a versão do formulário",
  "Please remove the special characters...!": "Remova os caracteres especiais...!",
  "No histories found": "Nenhum histórico encontrado",
  "Continue": "Prosseguir",
  "This will create a new version of the form without changing the path name but with new form id.": "Isso criará uma nova versão do formulário sem alterar o nome do caminho, mas com o novo ID do formulário.",
  "Save as a new version": "Salvar como uma nova versão",
  "Save New Version": "Salvar nova versão",
  "Do you want to save a new version of this form?": "Deseja salvar uma nova versão deste formulário?",
  "Do you want to create a duplicate form and associated workflow from existing form ?": "Deseja criar um formulário duplicado e um fluxo de trabalho associado a partir de um formulário existente?",
  "New version created": "New version created",
  "Create a duplicate form": "Criar um formulário duplicado",
  "Show more": "Mostre mais",
  "Modified By": "Modificado por",
  "Created On": "Criado em",
  "Formsflow automatically saves your previous form data. Now you can switch to the previous stage and edit.": "O Formsflow salva automaticamente os dados do formulário anterior. Agora você pode mudar para o estágio anterior e editar.",
  "Duplicate Form": "Formulário Duplicado",
  "Form History": "Histórico do formulário",
  "No file chosen": "Nenhum arquivo selecionado",
  "Choose File": "Escolher arquivo",
  "Choose any": "Escolha qualquer",
  "Sort by form name": "Classificar por nome do formulário",
  "select form type": "selecione o tipo de formulário",
  "Draft Deleted Successfully": "Rascunho excluído com sucesso",
  "Saved to Applications/Drafts": "Salvo em Aplicações/Rascunhos",
  "Unfinished applications will be saved to Applications/Drafts.": "Os aplicativos inacabados serão salvos em Aplicativos/Rascunhos.",
  "Discard Draft": "Descartar Rascunho",
  "with ID": "com identidade",
  "Please change the selected filters to view Forms": "Por favor, altere os filtros selecionados para visualizar os formulários",
  "Search by form name": "Pesquisar pelo nome do formulário",
  "Click to search": "Clique para pesquisar",
  "No submissions found": "Nenhum envio encontrado",
  "No submission avaliable in the selected date. Please select another date range": "Nenhuma submissão disponível na data selecionada. Selecione outro intervalo de datas",
  "Dashboards": "Painéis",
  "Form field": "Campo do formulário",
  "Enter Label": "Digite o rótulo",
  "Select form field": "Selecione o campo do formulário",
  "Add form fields to display in task list": "Adicionar campos de formulário para exibir na lista de tarefas",
  "Latest Version": "Última versão",
  "Submission Status": "Status de submissão",
  "No submission status": "Sem status de envio",
  "No Public url found": "Nenhum URL público encontrado",
  "Show in list": "Mostrar na lista",
  "Select Task Variable": "Selecione a variável de tarefa",
  "Enter your Label": "Digite seu rótulo",
  "Please select from one of the following workflows.": "Selecione um dos fluxos de trabalho a seguir.",
  "Select Key": "Selecionar chave",
  "Create Your Task Variable": "Crie sua variável de tarefa",
  "No task variable selected": "Nenhuma variável de tarefa selecionada",
  "Workflow Associate": "Associado de fluxo de trabalho",
  "Task variable": "Variável de tarefa",
  "Enter pathname": "Digite o nome do caminho",
  "Submission Saved": "Envio salvo.",
  "Error while Submission.": "Erro durante o envio.",
  "Submission cannot be done.": "O envio não pode ser feito.",
  "Error in getting Workflow Process.": "Erro ao obter o processo de fluxo de trabalho.",
  "Form saved": "Formulário salvo.",
  "Error while saving Form.": "Erro ao salvar o formulário.",
  "Forms": "Formulários",
  "Edit": "Editar",
  "Text Field": "Campo de texto",
  "Do you want to associate form with a workflow ?": "Você deseja associar o formulário a um fluxo de trabalho?",
  "Yes": "sim",
  "Type": "Tipo",
  "No": "Não",
  "Form Name": "Nome do formulario ",
  "Workflow Name": "Nome do fluxo de trabalho",
  "Publish this form for Client Users.": "Publique este formulário para usuários clientes.",
  "Comments": "Comentários",
  "Back": "Voltar",
  "Edit Form": "Editar Formulário",
  "Next": "Próxima",
  "Save": "Salve",
  "Back to Form List": "Voltar para a lista de formulários",
  "Submit New": "Enviar novo",
  "View Submissions": "Ver os envios",
  "View/Edit Form": "Ver / Editar Formulário",
  "Delete Form": "Apagar Formulário",
  "Delete": "Excluir",
  "Print As PDF": "Imprimir como PDF",
  "Applications": "Formulários",
  "Tasks": "Tarefas",
  "Dashboard": "Painel",
  "Logout": "Sair",
  "No tasks found": "Nenhuma tarefa encontrada",
  "Please change the selected filters to view tasks": "Por favor, mude os filtros selecionados para ver as tarefas",
  "Clear all filters": "Limpar todos os filtros",
  "Created": "Criada",
  "Task Variables": "Variáveis de Tarefa",
  "Process Variables": "Variáveis de Processo",
  "Process Definition Name": "Nome da Definição do Processo",
  "Assigne": "Cessionária",
  "Candidate Group": "Grupo de Candidatos",
  "Candidate User": "Usuário candidato",
  "Name": "Nome",
  "Description": "Descrição",
  "Priority": "Prioridade",
  "Due Date": "Data de vencimento",
  "Follow up Date": "Data de Acompanhamento",
  "Task name": "Nome da tarefa",
  "Remove sorting": "Remover classificação",
  "Asending": "Ascendente",
  "Descending": "descendente",
  "Add sorting": "Adicionar classificação",
  "of the criteria are met.": "dos critérios são atendidos.",
  "For Variable, ignore case of": "Para variável, ignore o caso de",
  "name": "nome",
  "value": "valor",
  "No task matching filters found.": "Nenhum filtro de correspondência de tarefa encontrado.",
  "Set follow-up Date": "Definir data de acompanhamento",
  "Set Due date": "Definir data de vencimento",
  "Add groups": "Adicionar grupos",
  "Claim": "Alegar",
  "Search By Last Name": "Pesquisar por Sobrenome",
  "Search By First Name": "Pesquisar pelo primeiro nome",
  "Search By Email": "Pesquisa por e-mail",
  "You can add a group by typing a group ID into the input field and afterwards clicking the button with the plus sign.": "Você pode adicionar um grupo digitando um ID de grupo no campo de entrada e, em seguida, clicando no botão com o sinal de mais.",
  "Add a group": "Adicionar um grupo",
  "Process Name": "Nome do processo",
  "Set FollowUp Date": "Definir data de acompanhamento",
  "Groups": "grupos",
  "Click to Change Assignee": "Clique para alterar o responsável",
  "Reset Assignee": "Reiniciar cessionário",
  "Update User": "Atualizar usuário",
  "Close": "Fechar",
  "Remove search": "Remover pesquisa",
  "Property": "Propriedade",
  "Operator": "Operadora",
  "Total number of results": "Número total de resultados",
  "Filter Tasks": "Filtrar tarefas",
  "ANY": "ALGUMA",
  "Task assignee": "Responsável pela tarefa",
  "Application History": "Histórico da Aplicação",
  "View Submission": "Ver o envio",
  "Showing": "Mostrando",
  "Results": "resultados",
  "Acknowledge": "Reconhecer",
  "Select a task in the list.": "Selecione uma tarefa na lista.",
  "No Filters Found": "Nenhum filtro encontrado",
  "Loading": "Carregando",
  "Form": "Forma",
  "History": "História",
  "Diagram": "Diagrama",
  "Delete Confirmation": "Confirmação de exclusão",
  "All tasks": "Todas as tarefas",
  "Manage Groups": "Gerenciar grupos",
  "Group ID": "ID do grupo",
  "Due": "Devida",
  "Follow-up": "Acompanhamento",
  "Please fix the errors before submitting again.": "Corrija os erros antes de enviar novamente.",
  "Form-Workflow association saved.":
    "Associação formulário-fluxo de trabalho salva.",
  "Form-Workflow association failed.":
    "Falha na associação Formulário-Fluxo de trabalho.",
  "Application Status": "Status do aplicativo",
  "Request Status": "Status da Solicitação",
  "Request Name": "Nome da Solicitação",
  "Submitted On": "Enviado em",
  "Modified On": "Modificado em",
  "No History Found": "Nenhum histórico encontrado",
  "Process Diagram": "Diagrama de Processo",
  "Details": "Detalhes",
  "example": "exemplo",
  "Path": "Caminho",
  "Status": "Estado",
  "Submissions": "Submissões",
  "No Application History found": "Nenhum histórico de aplicativo encontrado",
  "Link To Form Submission": "Link para o envio do formulário",
  "Last Modified": "Última modificação",
  "All": "Tudo",
  "view": "Visualizar",
  "Insights": "Intuições",
  "Metrics": "Métricas",
  "No dashboard found": "Nenhum painel encontrado",
  "to": "para",
  "of": "do",
  "Application ID": "ID do aplicativo",
  "Total Submissions": "Total de inscrições",
  "Operations": "Operações",
  "Access Groups": "Grupos de acesso",
  "Action": "Açao",
  "Add": "Adicionar",
  "Admin": "Administrador",
  "Create Form": "Criar formulário",
  "Upload Form": "Carregar formulário",
  "Download Form": "Baixar formulário",
  "Upload json form only": "Carregar apenas o formulário json",
  "Select atleast one form": "Selecione pelo menos um formulário",
  "Select All": "Selecionar tudo",
  "Created Date": "Data de criação",
  "Modified Date": "Data modificada",
  "File Upload Status": "Status de upload de arquivo",
  "Forms Completed": "Formulários preenchidos",
  "Form Completed": "Formulário preenchido",
  "No forms found": "Nenhum formulário encontrado",
  "Powered by ": "Distribuído por ",
  "No Process Diagram found": "Nenhum diagrama de processo encontrado",
  "No applications found": "Nenhum aplicativo encontrado",
  "Successfully Updated": "Atualizado com sucesso",
  "Failed": "Fracassada",
  "Press Enter key or click here to search": "Pressione a tecla Enter ou clique aqui para pesquisar",
  "Click to clear": "Clique para limpar",
  "Search...": "Procurar...",
  "Click here to back": "Clique aqui para voltar",
  'Enter the form title': "Digite o título do formulário",
  "Enter the form machine name": "Digite o nome da máquina do formulário",
  "Title": "Título",
  "Display as": "Mostrar como",
  "Anonymous Form ": "Formulário anônimo",
  "Make this form public ?": "Tornar este formulário público?",
  "Save & Preview": "Salvar e visualizar",
  "Select Dashboard": "Selecionar painel",
  "Design Form": "Formulário de Design",
  "Associate this form with a workflow?": "Associar este formulário a um fluxo de trabalho?",
  "Preview and Confirm": "Pré-visualizar e confirmar",
  "All steps completed - you're finished": "Todas as etapas concluídas - você terminou",
  "Downloaded Successfully": "Baixado com sucesso",
  "Error in JSON file structure": "Erro na estrutura do arquivo JSON",
  "Loading...": "Carregando...",
  "Wizard": "Mago",
  "Save Form": "Salvar formulário",
  "Confirm": "confirme",
  "Cancel": "Cancelar",
  "Please change the selected filters to view applications": "Por favor, altere os filtros selecionados para visualizar os aplicativos",
  "No submissions available for the selected date range": "Nenhum envio disponível para o período selecionado",
  "Ok": "OK",
  "Form not available": "Formulário não disponível",
  "Thank you for your response.": "Obrigado pela sua resposta.",
  "saved successfully": "Salvo com sucesso",
  "Copy anonymous form URL": "Copiar URL de formulário anônimo",
  "Submitted By": "Enviado por",
  "Confirmation": "Confirmação",
  "Changing the form title will not affect the existing applications. It will only update in the newly created applications. Press Save Changes to continue or cancel the changes.": "Alterar o título do formulário não afetará os aplicativos existentes. Ele só será atualizado nos aplicativos recém-criados. Pressione Salvar alterações para continuar ou cancelar as alterações.",
  "Error while saving Form": "Erro ao salvar o formulário",
  "Click here to go back": "Clique aqui para voltar",
  " Applications are submitted against": " As candidaturas são apresentadas contra",
  " Application is submitted against": " As candidaturas são apresentadas contra",
  "Are you sure to delete the": "Tem certeza de que deseja excluir",
  "Are you sure to delete the draft": "Tem certeza de que deseja excluir o rascunho",
  "Are you sure to delete this submission ?": "Tem certeza de que deseja excluir esta submissão?",
  " Submissions are made against": " As submissões são feitas contra",
  " Submission is made against": " A submissão é feita contra",
  "Save Changes": "Salvar alterações",
  "Changing the form workflow will not affect the existing applications. It will only update in the newly created applications. Press Save Changes to continue or cancel the changes.": "Alterar o fluxo de trabalho do formulário não afetará os aplicativos existentes. Ele só será atualizado nos aplicativos recém-criados. Pressione Salvar alterações para continuar ou cancelar as alterações.",
  "Form deletion unsuccessful": "Falha na exclusão do formulário",
  "Form deleted successfully": "Formulário excluído com sucesso",
  "Bundle deleted successfully": "Pacote excluído com sucesso",
  "Form process failed": "Falha no processo do formulário",
  "Form unpublishing Failed": "Falha ao cancelar a publicação do formulário",
  "All groups have access to the dashboard": "Todos os grupos têm acesso ao painel",
  "Export PDF": "Exportar PDF",
  "Something went wrong. Please try again!": "Algo deu errado. Por favor, tente novamente!",
  "No Dashboards Found": "Nenhum painel encontrado",
  "Processes": "Processos",
  "Please select an existing workflow.": "Selecione um fluxo de trabalho existente.",
  "Create New": "Crie um novo",
  "Or create new workflow or import a workflow from a local directory.": "Ou crie um novo fluxo de trabalho ou importe um fluxo de trabalho de um diretório local.",
  "The operation couldn't be completed. Please try after sometime": "A operação não pôde ser concluída. Por favor, tente depois de algum tempo",
  "Select...": "Selecionar...",
  "No Drafts Found": "Nenhum rascunho encontrado",
  "Help": "Ajuda",
  "Draft Id": "ID de rascunho",
  "Draft Name": "Nome do rascunho",
  "Link To Draft Submission": "Link para envio de rascunho",
  "Drafts": "Rascunhos",
  "You can't submit this form until it is published": "Você não pode enviar este formulário até que ele seja publicado",
  "Form not published": "Formulário não publicado",
  "Access Denied": "Acesso negado",
  "By default, the tenant key would be prefixed to form": "Por padrão, a chave do locatário seria prefixada para formar",
  "go back": "volte",
  "No Applications Found": "Nenhum aplicativo encontrado",
  "Submission saved to draft.": "Envio salvo como rascunho.",
  "Saved to draft": "Salvo no rascunho",
  "Saving...": "Salvando...",
  "Resource": "Recurso",
  "Error in creating form process mapper": "Erro ao criar mapeador de processo de formulário",
  "Select the date": "Selecione a data",
  "Diagram deployed": "Diagrama implantado",
  "Deployment failed": "Falha na implantação",
  "form uploaded": "formulário carregado",
  "forms uploaded": "formulários carregados",
  "Failed to upload": "Falha ao carregar",
  "forms...!": "formas...!",
  "form...!": "forma...!",
  "Process name(s) must not be empty": "O(s) nome(s) do processo não deve(m) estar vazio(s)",
  "URL copied": "URL copiado",
  "Click Here to Copy": "Clique aqui para copiar",
  "Resources": "Recursos",
  "Duplicate form created successfully": "Formulário duplicado criado com sucesso",
  "Revert": "Reverter",
  "Overview": "Visão geral",
  "Design Permission": "Permissão de design",
  "Application Permission": "Permissão de aplicativo",
  "All Designers": "Todos os Designers",
  "Private(only you)": "Privado(só você)",
  "Specific Designer Group": "Grupo específico de designers",
  "Submission Permission": "Permissão de envio",
  "All Users": "Todos os usuários",
  "Specific User Group": "Grupo de usuários específico",
  "Specific Reviewers": "Revisores Específicos",
  "All Reviewers": "Todos os revisores",
  "Permission for application tracking.": "Permissão para rastreamento de aplicativos.",
  "Criteria": "Critério",
  "Select Form": "Selecione o formulário",
  "Create Condition": "Criar condição",
  "Enter criteria": "Insira os critérios",
  "Make this form public": "Tornar este formulário público",
  "Enable bundling": "Ativar agrupamento",
  "Applicable for Designer Roled Users only.": "Aplicável apenas para usuários com função de designer.",
  "Applicable for Client and Reviewer Roled Users only.": "Aplicável apenas para usuários com função de cliente e revisor.",
  "Bundle Name": "Nome do pacote",
  "View/Edit": "Visualizar edição",
  "Create Bundle": "Criar pacote",
  "Rows per page": "Linhas por página",
  "Items per page": "Itens por página",
  "entries": "entradas",
  "Form Bundle": "Pacote de formulários",
  "No bundles found": "Nenhum pacote encontrado",
  "Please change the selected filters to view Bundles": "Por favor, altere os filtros selecionados para ver os pacotes",
  "Select all to download forms": "Selecione tudo para baixar os formulários",
  "Select for download": "Selecione para baixar",
  "Bundle Description": "Descrição do Pacote",
  "Conditions": "condições",
  "Enter name": "Insira o nome",
  "Enter Description": "Insira a descrição",
  "You can change the form order by drag and drop": "Você pode alterar a ordem do formulário arrastando e soltando",
  "Form Order": "Pedido de formulário",
  "Form Type": "Tipo de formulário",
  "Add Forms Launch together": "Adicionar formulários lançados juntos",
  "Form bundles can save your time by grouping forms together": "Pacotes de formulários podem economizar seu tempo agrupando formulários",
  "Add Forms": "Adicionar formulários",
  "Create Conditions": "Criar Condições",
  " Add Conditions": "Adicionar condições",
  "Select Forms": "Selecionar formulários",
  "Insert": "Inserir",
  "View Form": "Ver formulário",
  "Publish this Bundle for Client Users.": "Publique este pacote para usuários clientes",
  "Total Forms": "Formulários totais",
  "Edit Bundle": "Editar pacote",
  "Next Form": "Próximo formulário",
  "Previous Form": "Formulário anterior",
  "Forms included under the package": "Formulários incluídos no pacote",
  "Submit": "Enviar",
  "Reset Zoom": "Redefinir zoom",
  "Zoom In": "Mais Zoom",
  "Zoom Out": "Reduzir o zoom",
  "Deploy": "Implantar",
  "Export": "Exportar",
  "Design Bundle": "Pacote de design",
  "Workflow": "fluxo de trabalho",
  "Bundle Name and Description is Required": "O nome e a descrição do pacote são obrigatórios",
  "Bundle Description is Required": "A descrição do pacote é obrigatória",
  "Bundle Create Successfully": "Pacote criado com sucesso",
  "Bundle updated successfully": "Pacote atualizado com sucesso",
  "Select": "Selecione",
  "Unselect": "Desmarcar",
  "Done": "Feito",
  "Failed to update permission!": "Falha ao atualizar a permissão!",
  "Failed to fetch roles!": "Falha ao buscar papéis!",
  "Failed to update role!": "Falha ao atualizar função!",
  "Permission updated successfully!": "Permissão atualizada com sucesso!",
  "Role updated successfully!": "Função atualizada com sucesso!",
  "Update failed!": "Atualização falhou!",
  "Clear": "Claro",
  "Are you sure deleting the role": "Tem certeza de que está excluindo a função",
  "Confirm Delete": "Confirmar exclusão",
  "All roles": "Todas as funções",
  "Filter By:": "Filtrar por:",
  "Create": "Criar",
  "Eg: Account Manager": "Ex: Gerente de contas",
  "View": "Visualizar",
  "Search by name, username or email": "Pesquise por nome, nome de usuário ou e-mail",
  "No data Found": "Nenhum dado encontrado",
  "Roles": "Funções",
  "Users": "Usuários",
  "Create New Role": "Criar nova função",
  "Search by role name": "Pesquise pelo nome da função",
  "Role Name": "Nome do papel",
  "Actions": "Ações",
  "Create Role": "Criar função",
  "Email": "Email",
  "Role": "Papel",
  "Add Role": "Adicionar Função",
  "Role deleted successfully!": "Função excluída com sucesso!",
  "Failed to delete role!": "Falha ao excluir a função!",
  "Role created successfully!": "Função criada com sucesso!",
  "Failed to create role!": "Falha ao criar função!",
  "Update success!": "Atualização feita com sucesso!",
  "No results found": "Nenhum resultado encontrado",
  "Edit Role": "Editar função",
  "Failed to fetch users!": "Falha ao buscar usuários!",
  "Role names cannot contain special characters except   _ , -": "Os nomes das funções não podem conter caracteres especiais, exceto _ , -",
  "Role names cannot contain special characters except _ , - , / ": "Os nomes das funções não podem conter caracteres especiais, exceto _ , - , /",
  "Rules deployed successfully": "Regras implantadas com sucesso",
  "Choose From Template": "Escolher a partir de um modelo",
  "Back to forms": "Voltar aos formulários",
  "Choose From Templates": "Escolher a partir de modelos",
  "Explore our Template Library to find the perfect combination of forms and workflows. Click to preview a template and, if it fits your needs, customize it to your liking. Once you've tailored it to your specific requirements, you're all set to create your business case": "Explore a nossa Biblioteca de Modelos para encontrar a combinação perfeita de formulários e fluxos de trabalho. Clique para visualizar um modelo e, se ele atender às suas necessidades, personalize-o ao seu gosto. Depois de ajustá-lo às suas necessidades específicas, você estará pronto para criar o seu caso de negócio",
  "All templates": "Todos os modelos",
  "Template count": "Contagem de modelos",
  "Learn more": "Saiba mais",
  "Template Details": "Detalhes do modelo",
  "Template Title": "Título do modelo",
  "Preview Form": "Visualizar formulário",
  "Connected Workflow": "Fluxo de trabalho conectado",
  "Delete Template": "Excluir modelo",
  "Use Template": "Usar modelo",
  "Template created successfully": "Modelo criado com sucesso",
  "Template deleted successfully": "Modelo excluído com sucesso",
  "Template updated successfully": "Modelo atualizado com sucesso",
  "Category": "Categoria",
  "Search category...": "Pesquisar categoria...",
  "Save as Template": "Salvar como modelo",
  "Create Template": "Criar modelo",
  "Select or Create Category": "Selecionar ou criar categoria",
  "Short Intro": "Introdução curta",
  "Enter short intro": "Digite uma introdução curta",
  "Enter description": "Digite uma descrição",
  "Select or create": "Selecionar ou criar",
  "Update Template": "Atualizar modelo",
  "Form created successfully": "Formulário criado com sucesso",
  "select or create": "Selecionar ou criar",
  "Update Associated Form": "Atualizar Formulário Associado",
  "Update Associated Workflow": "Atualizar Fluxo de Trabalho Associado",
  "Enter title": "Inserir título",
  "No Templates Found": "Nenhum modelo encontrado",
  "No Category Found": "Nenhuma categoria encontrada",
  "Save Template": "Salvar Modelo",
  "You can type and click the": "Você pode digitar e clicar em",
  "to create the category.": "para criar a categoria.",
  "select or type to create": "selecione ou digite para criar",
  "BPMN": "BPMN",
  "DMN": "DMN",
  "Advanced Designer": "Designer Avançado",
  "Basic Designer": "Designer Básico",
  "Search": "Pesquisar",
  "Key": "Chave",
  "No Process Found": "Processo Não Encontrado",
  "Search workflow": "Pesquisar Fluxo de Trabalho",
  "No Dmn Found": "DMN Não Encontrado",
  "Submission ID": "ID da Submissão",
  "Submission Name": "Nome da Submissão",
  "Submission History": "Histórico da Submissão",
  "No Submission History found": "Nenhum Histórico de Submissão Encontrado",
  "Error in submission count": "Erro na contagem de submissões",
  "Permission for submission tracking.": "Permissão para rastreamento de submissões.",
  "Reviewer permission to view submissions": "Permissão do revisor para visualizar envios",
  "Permission to create new submission": "Permissão para criar novo envio",
  "Back to Bundle List": "Voltar para a Lista de Pacotes",
  "Bundle": "Pacote",
  "You can drag these nodes to the pane on the right.": "Você pode arrastar esses nós para o painel à direita.",
  "You can delete the element by select and click the backspace": "Você pode excluir o elemento selecionando e clicando na tecla backspace",
  "Workflow Id": "ID do Fluxo de Trabalho",
  "Enter Workflow Id": "Insira o ID do Fluxo de Trabalho",
  "Back to Submissions": "Voltar para as Submissões",
  "Back to Drafts": "Voltar para os Rascunhos",
  "Choose Your Workflow Option": "Escolha sua Opção de Fluxo de Trabalho",
  "Create New Workflow": "Criar Novo Fluxo de Trabalho",
  "Choose this option when you want to create a fresh workflow based on the template. System will generate and deploy a new workflow, then connect it to the selected form. Opt for this if your form requires a distinct set of workflow steps tailored to its specific needs.": "Escolha esta opção quando desejar criar um novo fluxo de trabalho com base no modelo. O sistema irá gerar e implantar um novo fluxo de trabalho e, em seguida, conectá-lo ao formulário selecionado. Opte por isso se o seu formulário exigir um conjunto distinto de etapas de fluxo de trabalho adaptadas às suas necessidades específicas.",
  "Reuse Existing Workflow": "Reutilizar Fluxo de Trabalho Existente",
  "Select this option to link the form with an already established workflow. Any modifications made to the existing workflow will automatically reflect in this form as well. This is ideal when you want to leverage the existing workflow for your form's requirements.": "Selecione esta opção para vincular o formulário a um fluxo de trabalho já estabelecido. Quaisquer modificações feitas no fluxo de trabalho existente serão refletidas automaticamente neste formulário. Isso é ideal quando você deseja aproveitar o fluxo de trabalho existente para atender às necessidades do seu formulário",
  "Are you sure you wish to delete the Template?": "Tem certeza de que deseja excluir o Modelo?",
  "Enter Workflow Name": "Inserir Nome do Fluxo de Trabalho",
  "Create Workflow": "Criar Fluxo de Trabalho",
  "Upload Workflow": "Carregar Fluxo de Trabalho",
  "Edit Workflow": "Editar Fluxo de Trabalho",
  "Published": "Publicado",
  "Unpublished": "Não Publicado",
  "Private": "Privado",
  "Anonymous": "Anônimo",
  "Edit Processes": "Editar Processos",
  "Create Processes": "Criar Processos",
  "tasks": "tarefas",
  "Submission Id": "ID da Submissão",
  "Clear All Filters": "Limpar Todos os Filtros",
  "Show results": "Mostrar resultados",
  "Delete Draft": "Excluir Rascunho",
  "I can help you design forms effortlessly- all you have to do is give me a clear and concise description of what the form is meant to do. I will build out the fields and layout for you,which you can further customize.": "Posso ajudar você a projetar formulários sem esforço - tudo o que você precisa fazer é me fornecer uma descrição clara e concisa do que o formulário deve fazer. Vou criar os campos e o layout para você, que você pode personalizar ainda mais.",
  "I am": "Eu sou",
  "For example 'Give us a form that will serve as a patient intake form for walk-in clinics'": "Por exemplo 'Nos dê um formulário que servirá como um formulário de admissão de pacientes para clínicas sem necessidade de agendamento'.",
  "Enter your prompt here": "Digite o prompt aqui",
  "Would you like to continue?": "Gostaria de continuar?",
  "Restart": "Reiniciar",
  "I'm here to provide further assistance. Thank you!": "Estou aqui para fornecer assistência adicional. Obrigado!",
  "Generate Form Design": "Gerar Design do Formulário",
  "Preview": "visualização",
  "Your form is now ready!": "Seu formulário está pronto!",
  "Please tell me all the specifications and requirements you have in mind for your form. After you have finished the description, hit send.": "Por favor, nos forneça todas as especificações e requisitos que você tem em mente para o seu formulário. Depois de terminar a descrição, pressione Enter.",
  "Choose template": "Escolher modelo",
  "Search by bundle name": "Pesquisar por nome do pacote",
  "Save Bundle": "Salvar Bundle",
  "Id": "Id",
  "Enable bundling ?": "Ativar agrupamento?",
  "Select a task in the List.": "Selecione uma tarefa na lista.",
  "Add Date": "Adicionar data",
  "Assign to Me": "Atribuir para mim",
  "Filter count:": "Contagem de filtros:",
  "Selected Forms": "Formulários Selecionados",
  "Template Used:": "Modelo Utilizado:",
  "Select a task from the List.": "Selecione uma tarefa da lista.",
  "Choose a task from the list; your selection directs your current activity.": "Escolha uma tarefa da lista; sua seleção direciona sua atividade atual.",
  "Please change the selected filters to view submissions": "Por favor, altere os filtros selecionados para ver as submissões",
  "Please change the selected filters to view drafts": "Por favor, altere os filtros selecionados para ver os rascunhos",
  "No drafts found": "Nenhum rascunho encontrado",
  "Flow-E helps to build faster": "Flow-E ajuda a construir mais rápido",
  "Flow-E is not available for demonstration in the trial instance. You can experience Flow-E in any of the premium packages of the product": "Flow-E não está disponível para demonstração na instância de teste. Você pode experimentar o Flow-E em qualquer um dos pacotes premium do produto",
  "Get in touch with formsflow.ai team": "Entre em contato com a equipe da formsflow.ai",
  "Premium": "Premium"
};
