/* eslint-disable max-len */
export const RESOURCE_BUNDLES_FR = {
  "Submit Form": "Soumettre le Formulaire",
  "Changes Applied Successfully": "Changements Appliqués avec Succès",
  "Filter Deleted Successfully": "Filtre Supprimé avec Succès",
  "Save Filter": "Enregistrer le Filtre",
  "Filter Name": "Nom du Filtre",
  "Create new filter": "Créer un nouveau filtre",
  "Definition Key": "Clé de Définition",
  "Permission": "Permission",
  "Enter your text here": "Entrez votre texte ici",
  "Accessible for all users": "Accessible pour tous les utilisateurs",
  "Private (Only You)": "Privé (Uniquement Vous)",
  "Specific Group": "Groupe Spécifique",
  "Select Elements": "Sélectionner des Éléments",
  " Task Attributes Selected": " Attributs de la Tâche Sélectionnés",
  "Delete Filter": "Supprimer le Filtre",
  "Add Value": "Ajouter une Valeur",
  "Show only current user assigned task": "Afficher uniquement les tâches attribuées à l'utilisateur actuel",
  "Show task based on logged user roles": "Afficher les tâches en fonction des rôles de l'utilisateur connecté",
  "Create Filter": "Créer un Filtre",
  "Task Attributes": "Attributs de la Tâche",
  "Only selected task attributes will be available on task list view": "Seuls les attributs de tâche sélectionnés seront disponibles dans la vue de la liste des tâches",
  "Show undefined variables": "Afficher les variables non définies",
  "Label": "Étiquette",
  "Variables": "Variables",
  "Task Title": "Titre de la Tâche",
  "List View": "Vue Liste",
  "Card View": "Vue Carte",
  "Create New Filter": "Créer un Nouveau Filtre",
  "Sort by": "Trier par",
  "Collapse All": "Réduire Tout",
  "Expand All": "Étendre Tout",
  "Application Id": "ID de l'Application",
  "Back to task": "Retour à la tâche",
  "Filters": "Filtres",
  "Assignee": "Assigné",
  "Select a user": "Sélectionner un utilisateur",
  "Create DMN": "Créer DMN",
  "Create Process": "Créer Processus",
  "Edit DMN": "Éditer DMN",
  "More options": "Plus d'options",
  "Form Description": "Description du formulaire",
  "Saved to Submissions/Drafts": "Enregistré dans Soumissions/Brouillons",
  "Unfinished submissions will be saved to Submissions/Drafts.": "Les soumissions inachevées seront enregistrées dans Soumissions/Brouillons.",
  "Edit Process": "Modifier le processus",
  "Add filter name": "Ajouter un nom de filtre",
  "This section is aimed to set the parameters used to filter the tasks": "Cette section vise à définir les paramètres utilisés pour filtrer les tâches",
  "This section is aimed to set read permissions for the filter": "Cette section vise à définir les autorisations de lecture pour le filtre",
  "This section is aimed to set select task attributes that will be visible in the task list view": "Cette section vise à définir les attributs de tâche sélectionnés qui seront visibles dans la vue de la liste des tâches",
  "Task Attribute": "Attribut de la tâche",
  "You can define variables shown in the list": "Vous pouvez définir des variables affichées dans la liste",
  "results": "Résultats",
  "Enter role name": "Entrez le nom du rôle",
  "Public": "Public",
  "View Submitted Form": "Voir le formulaire soumis",
  "View Draft Details": "Voir les détails du brouillon",
  "Edit Draft": "Éditer le brouillon",
  "Draft Title": "Titre du brouillon",
  "Filter": "Filtre",
  "Advanced Options": "Options avancées",
  "Enter the path name": "Entrer le nom du chemin",
  "Search by form title": "Rechercher par titre de formulaire",
  "Associate Workflow": "Associer le flux de travail",
  "Search by workflow name": "Rechercher par nom de flux de travail",
  "View Details": "Voir les détails",
  "Visibility": "Visibilité",
  "Form Title": "Titre du formulaire",
  "Access restricted by its designer..!": "Accès restreint par son concepteur..!",
  "and you will no longer have the access to this form in bundles": "et vous n'aurez plus accès à ce formulaire dans les bundles",
  "You will no longer have the access to this form in bundles": "Vous n'aurez plus accès à ce formulaire dans les bundles",
  "Bad Request": "Bad Request",
  "Select the form to be displayed based on the  above criteria": "Sélectionnez le formulaire à afficher en fonction des critères ci-dessus.",
  "Create the criteria using the API key of any form component and a conditional notation. For example, 'age > 18' where 'age' is the API key of a form component": "Créez les critères en utilisant la clé API de n'importe quel composant de formulaire et une notation conditionnelle. Par exemple, 'age > 18', où 'age' est la clé API d'un composant de formulaire.",
  "Select form version": "Sélectionnez la version du formulaire",
  "Please remove the special characters...!": "Veuillez supprimer les caractères spéciaux... !",
  "No histories found": "Aucun historique trouvé",
  "Continue": "Continuez",
  "This will create a new version of the form without changing the path name but with new form id.": "Cela créera une nouvelle version du formulaire sans changer le nom du chemin mais avec un nouvel identifiant de formulaire.",
  "Save as a new version": "Enregistrer en tant que nouvelle version",
  "Save New Version": "Enregistrer la nouvelle version",
  "Do you want to save a new version of this form?": "Voulez-vous enregistrer une nouvelle version de ce formulaire ?",
  "Do you want to create a duplicate form and associated workflow from existing form ?": "Vous souhaitez créer un formulaire dupliqué et un workflow associé à partir d'un formulaire existant ?",
  "New version created": "Nouvelle version créée",
  "Create a duplicate form": "Créer un formulaire en double",
  "Show more": "Montre plus",
  "Modified By": "Modifié par",
  "Created On": "Créé sur",
  "Formsflow automatically saves your previous form data. Now you can switch to the previous stage and edit.": "Formsflow enregistre automatiquement vos données de formulaire précédentes. Vous pouvez maintenant passer à l'étape précédente et modifier.",
  "Duplicate Form": "Formulaire en double",
  "Form History": "Historique du formulaire",
  "No file chosen": "Aucun fichier choisi",
  "Choose File": "Choisir le fichier",
  "Choose any": "Choisissez n'importe quel",
  "Sort by form name": "Trier par nom de formulaire",
  "select form type": "sélectionner le type de formulaire",
  "Draft Deleted Successfully": "Brouillon supprimé avec succès",
  "Saved to Applications/Drafts": "Enregistré dans Candidatures/Brouillons",
  "Unfinished applications will be saved to Applications/Drafts.": "Les candidatures non terminées seront enregistrées dans Candidatures/Brouillons.",
  "Discard Draft": " Supprimer le brouillon",
  "with ID": "avec pièce d'identité",
  "Please change the selected filters to view Forms": "Veuillez modifier les filtres sélectionnés pour afficher les formulaires",
  "Search by form name": "Rechercher par nom de formulaire",
  "Click to search": "Cliquez pour rechercher",
  "No submissions found": "Aucune soumission trouvée",
  "No submission avaliable in the selected date. Please select another date range": "Aucune soumission disponible à la date sélectionnée. Veuillez sélectionner une autre plage de dates",
  "Dashboards": "Tableaux de bord",
  "Form field": "Champ de formulaire",
  "Enter Label": "Entrez l'étiquette",
  "Select form field": "Sélectionnez le champ du formulaire",
  "Add form fields to display in task list": "Ajouter des champs de formulaire à afficher dans la liste des tâches",
  "Latest Version": "Dernière version",
  "Submission Status": "État de la soumission",
  "No submission status": "Aucun statut de soumission",
  "No Public url found": "Aucune URL publique trouvée",
  "Show in list": "Afficher dans la liste",
  "Select Task Variable": "Sélectionnez la variable de tâche",
  "Enter your Label": "Entrez votre étiquette",
  "Please select from one of the following workflows.": "Veuillez sélectionner l'un des workflows suivants.",
  "Select Key": "Sélectionnez la clé",
  "Create Your Task Variable": "Créez votre variable de tâche",
  "No task variable selected": "Aucune variable de tâche sélectionnée",
  "Workflow Associate": "Associé au flux de travail",
  "Task variable": "Variable de tâche",
  "Enter pathname": "Entrez le nom du chemin",
  "Submission Saved": "Soumission enregistrée.",
  "Error while Submission.": "Erreur lors de la soumission.",
  "Form saved": "Formulaire enregistré.",
  "Message": "Veuillez corriger les erreurs avant de soumettre à nouveau.",
  "Submission cannot be done.": "La soumission ne peut pas être faite.",
  "Form-Workflow association saved.":
    "Association formulaire-workflow enregistrée.",
  "Form-Workflow association failed.":
    "L'association formulaire-workflow a échoué.",
  "No Process Diagram found": "Aucun diagramme de processus trouvé",
  "No forms found": "Aucun formulaire trouvé",
  "All steps completed - you're finished": "Toutes les étapes terminées - vous avez terminé",
  "Edit": "Éditer",
  'Text Field': 'champ de texte',
  "Do you want to associate form with a workflow ?": "Vous souhaitez associer un formulaire à un workflow ?",
  "Yes": "Oui",
  "No": "Non",
  "Form Name": "Nom de forme ",
  "Workflow Name": "Nom du flux de travail ",
  "Publish this form for Client Users.": "Publiez ce formulaire pour les utilisateurs clients.",
  "Comments": "commentaires",
  "Back": "Arrière",
  "Edit Form": "Modifier le formulaire",
  "Next": "Prochaine",
  "Save": "sauvegarder",
  "Back to Form List": "Retour à la liste des formulaires",
  "Submit New": "Soumettre Nouveau",
  "View Submissions": "Voir les soumissions",
  "View/Edit Form": "Afficher/Modifier le formulaire",
  "Delete Form": "Supprimer le formulaire",
  "Delete": "Effacer",
  "Print As PDF": "Imprimer en PDF",
  "Applications": "Applications",
  "Tasks": "Tâches",
  "Metrics": "Métrique",
  "Insights": "Connaissances",
  "Dashboard": "Tableau de bord",
  "Logout": "Se déconnecter",
  "No tasks found": "Aucune tâche trouvée",
  "Please change the selected filters to view tasks": "Veuillez modifier les filtres sélectionnés pour afficher les tâches",
  "Clear all filters": "Effacer tous les filtres",
  "Created": "Créé",
  "Task Variables": "Variables de tâche",
  "Process Variables": "Variables de processus",
  "Process Definition Name": "Nom de la définition de processus",
  "Assigne": "Cessionnaire",
  "Candidate Group": "Groupe de candidats",
  "Candidate User": "Utilisateur candidat",
  "Name": "Nom",
  "Description": "La description",
  "Priority": "Priorité",
  "Due Date": "Date d'échéance",
  "Follow up Date": "Date de suivi",
  "Task name": "Nom de la tâche",
  "Remove sorting": "Supprimer le tri",
  "Ascending": "Ascendante",
  "Descending": "Descendante",
  "Add sorting": "Ajouter un tri",
  "of the criteria are met.": "des critères sont remplis.",
  "For Variable, ignore case of": "Pour Variable, ignorez la casse de",
  "value": "valeur",
  "No task matching filters found.": "Aucun filtre de correspondance de tâche trouvé.",
  "Set follow-up Date": "Définir la date de suivi",
  "Set Due date": "Définir la date d'échéance",
  "Add groups": "Ajouter des groupes",
  "Claim": "Réclamer",
  "Search By Last Name": "Recherche par nom de famille",
  "Search By First Name": "Recherche par prénom",
  "Search By Email": "Recherche par e-mail",
  "You can add a group by typing a group ID into the input field and afterwards clicking the button with the plus sign.": "Vous pouvez ajouter un groupe en saisissant un identifiant de groupe dans le champ de saisie, puis en cliquant sur le bouton avec le signe plus.",
  "Add a group": "Ajouter un groupe",
  "Process Name": "Nom du processus",
  "Set FollowUp Date": "Définir la date de suivi",
  "Groups": "groupes",
  "Click to Change Assignee": "Cliquez pour changer de cessionnaire",
  "Reset Assignee": "Réinitialiser le cessionnaire",
  "Update User": "Mettre à jour l'utilisateur",
  "Close": "proche",
  "Type": "Taper",
  "Remove search": "Supprimer la recherche",
  "Property": "Biens",
  "Operator": "Opératrice",
  "Total number of results": "Nombre total de résultats",
  "Filter Tasks": "Filtrer les tâches",
  "ANY": "TOUTE",
  "Task assignee": "Affecté à la tâche",
  "Application History": "Historique des candidatures",
  "View Submission": "Voir la soumission",
  "Showing": "Affichage",
  "to": "à",
  "of": "de",
  "Results": "résultats",
  "Acknowledge": "Accuser réception",
  "Select a task in the list.": "Sélectionnez une tâche dans la liste.",
  "No Filters Found": "Aucun filtre trouvé",
  "Loading": "Chargement",
  "Form": "Former",
  "History": "Histoire",
  "Diagram": "Diagramme",
  "Delete Confirmation": "Confirmation de suppression",
  "All tasks": "Toutes les tâches",
  "Manage Groups": "Gérer les groupes",
  "Group ID": "Identifiant du groupe",
  "Due": "Dû",
  "Follow-up": "Suivre",
  "Application ID": "ID d'application",
  "Application Name": "Nom de l'application",
  "Created By": "Créé par",
  "Application Status": "État de la candidature",
  "Request Status": "État de la demande",
  "Request Name": "Nom de la demande",
  "Submitted On": "Soumis le",
  "Modified On": "Modifié le",
  "No History Found": "Aucun historique trouvé",
  "Process Diagram": "Diagramme de processus",
  "Details": "Des détails",
  "Status": "Statut",
  "Submissions": "Soumissions",
  "No Application History found": "Aucun historique des applications trouvé",
  "Link To Form Submission": "Lien vers la soumission de formulaire",
  "Last Modified": "Dernière modification",
  "All": "Toute",
  "View": "Vue",
  "No dashboard found": "Aucun tableau de bord trouvé",
  "Total Submissions": "Nombre total de soumissions",
  "Operations": "Opérations",
  "Access Groups": "Groupes d'accès",
  "Action": "Action",
  "Add": "Ajouter",
  "Admin": "Administrateur",
  "Create Form": "Créer un formulaire",
  "Upload Form": "Télécharger le formulaire",
  "Download Form": "Télécharger le formulaire",
  "Upload json form only": "Télécharger le formulaire json uniquement",
  "Select atleast one form": "Sélectionnez au moins un formulaire",
  "Select All": "Tout sélectionner",
  "Created Date": "Date de création",
  "Modified Date": "Date modifiée",
  "File Upload Status": "État du téléchargement du fichier",
  "Forms Completed": "Formulaires remplis",
  "Form Completed": "Formulaire complété",
  " No forms found": "Aucun formulaire trouvé",
  "Powered by ": "Alimenté par ",
  "No applications found": "Aucune application trouvée",
  "Successfully Updated": "Mise à jour réussie",
  "Failed": "Manquée",
  "Submission Failed.": "Изпращането не бе успешно.",
  "Press Enter key or click here to search": "Appuyez sur la touche Entrée ou cliquez ici pour rechercher",
  "Click to clear": "Cliquez pour effacer",
  "Click here to back": "Cliquez ici pour revenir",
  "Enter the form machine name": "Entrez le nom de la machine de formulaire",
  'Enter the form title': "Entrez le titre du formulaire",
  "Title": "Titre",
  "Display as": "Afficher comme",
  "Anonymous Form ": "Formulaire anonyme",
  "Make this form public ?": "Rendre ce formulaire public ?",
  "Save & Preview": "Enregistrer et prévisualiser",
  "Select Dashboard": "Sélectionnez Tableau de bord",
  "Design Form": "Formulaire de conception",
  "Associate this form with a workflow?": "Associer ce formulaire à un workflow ?",
  "Preview and Confirm": "Aperçu et confirmation",
  "Forms": "Formes",
  "Downloaded Successfully": "Téléchargé avec succès",
  "Error in JSON file structure": "Erreur dans la structure du fichier JSON",
  "Path": "Chemin",
  "Example": "Exemple",
  "Please fix the errors before submitting again.": "Veuillez corriger les erreurs avant de soumettre à nouveau.",
  "Loading...": "Chargement...",
  "Wizard": "sorcier",
  "Save Form": "Enregistrer le formulaire",
  "Confirm": "Confirmer",
  "Cancel": "Annuler",
  "Please change the selected filters to view applications": "Veuillez modifier les filtres sélectionnés pour afficher les candidatures",
  "No submissions available for the selected date range": "Aucune soumission disponible pour la plage de dates sélectionnée",
  "Ok": "D'accord",
  "Form not available": "Formulaire non disponible",
  "Thank you for your response.": "Merci pour votre réponse.",
  "saved successfully": "enregistré avec succès",
  "Copy anonymous form URL": "Copier l'URL du formulaire anonyme",
  "Submitted By": "Proposé par",
  "Search...": "Recherche...",
  "Confirmation": "Confirmation",
  "Changing the form title will not affect the existing applications. It will only update in the newly created applications. Press Save Changes to continue or cancel the changes.": "La modification du titre du formulaire n'affectera pas les applications existantes. Il ne sera mis à jour que dans les applications nouvellement créées. Appuyez sur Enregistrer les modifications pour continuer ou annuler les modifications.",
  "Error while saving Form": "Erreur lors de l'enregistrement du formulaire",
  "Click here to go back": "Cliquez ici pour revenir en arrière",
  " Applications are submitted against": " Les candidatures sont déposées contre",
  " Application is submitted against": " La candidature est déposée contre",
  "Are you sure to delete the": "Êtes-vous sûr de vouloir supprimer",
  "Are you sure to delete the draft": "Êtes-vous sûr de vouloir supprimer le brouillon",
  "Are you sure to delete this submission ?": "Êtes-vous sûr de vouloir supprimer cette soumission ?",
  " Submissions are made against": " Les soumissions sont faites contre",
  " Submission is made against": " La soumission est faite contre",
  "Save Changes": "Save Changes",
  "Changing the form workflow will not affect the existing applications. It will only update in the newly created applications. Press Save Changes to continue or cancel the changes.": "La modification du flux de travail du formulaire n'affectera pas les applications existantes. Il ne sera mis à jour que dans les applications nouvellement créées. Appuyez sur Enregistrer les modifications pour continuer ou annuler les modifications.",
  "Form deleted successfully": "Formulaire supprimé avec succèsy",
  "Bundle deleted successfully": "Bundle supprimé avec succès",
  "Form deletion unsuccessful": "Échec de la suppression du formulaire",
  "Form process failed": "Le processus de formulaire a échoué",
  "Form unpublishing Failed": "Échec de la dépublication du formulaire",
  "All groups have access to the dashboard": "Tous les groupes ont accès au tableau de bord",
  "Export PDF": "Exporter PDF",
  "Something went wrong. Please try again!": "Quelque chose s'est mal passé. Veuillez réessayer!",
  "No Dashboards Found": "Aucun tableau de bord trouvé",
  "Processes": "Processus",
  "Please select an existing workflow.": "Veuillez sélectionner un workflow existant.",
  "Create New": "Créer un nouveau",
  "Or create new workflow or import a workflow from a local directory.": "Ou créez un nouveau flux de travail ou importez un flux de travail à partir d'un répertoire local.",
  "The operation couldn't be completed. Please try after sometime": "L'opération n'a pas pu être terminée. Veuillez essayer après un certain temps",
  "Select...": "Sélectionner...",
  "No Drafts Found": "Aucun brouillon trouvé",
  "Help": "Aider",
  "Draft Id": "ID brouillon",
  "Draft Name": "Nom provisoire",
  "Link To Draft Submission": "Lien vers la soumission de brouillon",
  "Drafts": "Brouillons",
  "You can't submit this form until it is published": "Vous ne pouvez pas soumettre ce formulaire tant qu'il n'est pas publié",
  "Form not published": "Formulaire non publié",
  "Access Denied": "Accès refusé",
  "By default, the tenant key would be prefixed to form": "Par défaut, la clé du locataire serait préfixée pour former",
  "go back": "retourner",
  "No Applications Found": "Aucune application trouvée",
  "Submission saved to draft.": "Soumission enregistrée comme brouillon.",
  "Saved to draft": "Enregistré comme brouillon",
  "Saving...": "Économie...",
  "Resource": "Ressource",
  "Error in creating form process mapper": "Erreur lors de la création du mappeur de processus de formulaire",
  "Select the date": "Sélectionnez la date",
  "Diagram deployed": "Diagramme déployé",
  "Deployment failed": "Échec du déploiement",
  "form uploaded": "formulaire téléchargé",
  "forms uploaded": "formulaires téléchargés",
  "Failed to upload": "Échec du téléchargement",
  "forms...!": "formes...!",
  "form...!": "formulaire...!",
  "Process name(s) must not be empty": "Le(s) nom(s) de processus ne doivent pas être vides",
  "URL copied": "URL copiée",
  "Click Here to Copy": "Cliquez ici pour copier",
  "Resources": "Ressources",
  "Duplicate form created successfully": "Formulaire en double créé avec succès",
  "Revert": "Revenir",

  "Select Form": "Sélectionnez le formulaire",
  "Create Condition": "Créer une condition",
  "Enter criteria": "Saisir des critères",
  "Make this form public": "Rendre ce formulaire public",
  "Enable bundling": "Activer le regroupement",
  "Bundle Name": "Nom du groupe",
  "View/Edit": "Voir la modification",
  "Create Bundle": "Créer un lot",
  "Rows per page": "Lignes par page",
  "Items per page": "Éléments par page",
  "entries": "entrées",
  "Form Bundle": "Ensemble de formulaires",
  "No bundles found": "Aucun groupe trouvé",
  "Please change the selected filters to view Bundles": "Veuillez modifier les filtres sélectionnés pour afficher les offres groupées",
  "Select all to download forms": "Tout sélectionner pour télécharger les formulaires",
  "Select for download": "Sélectionnez pour télécharger",
  "Bundle Description": "Description du lot",
  "Conditions": "Conditions",
  "Enter name": "Entrez le nom",
  "Enter Description": "Entrez la description",
  "You can change the form order by drag and drop": "Vous pouvez modifier l'ordre du formulaire par glisser-déposer",
  "Form Order": "Bon de commande",
  "Form Type": "Type de formulaire",
  "Add Forms Launch together": "Ajouter des formulaires Lancer ensemble",
  "Form bundles can save your time by grouping forms together": "Les groupes de formulaires peuvent vous faire gagner du temps en regroupant les formulaires",
  "Add Forms": "Ajouter des formulaires",
  "Criteria": "Critère",
  "Create Conditions": "Créer des conditions",
  " Add Conditions": "Ajouter des conditions",
  "Select Forms": "Sélectionnez les formulaires",
  "Insert": "Insérer",
  "View Form": "Afficher le formulaire",
  "Publish this Bundle for Client Users.": "Publier ce bundle pour les utilisateurs clients",
  "Total Forms": "Nombre total de formulaires",
  "Edit Bundle": "Modifier le groupe",
  "Next Form": "Formulaire suivant",
  "Previous Form": "Formulaire précédent",
  "Forms included under the package": "Formulaires inclus dans le forfait",
  "Submit": "Soumettre",
  "Reset Zoom": "Réinitialiser le zoom",
  "Zoom In": "Agrandir",
  "Zoom Out": "Dézoomer",
  "Deploy": "Déployer",
  "Export": "Exporter",
  "Overview": "Aperçu",
  "Design Permission": "Autorisation de conception",
  "Application Permission": "Autorisation de candidature",
  "All Designers": "Tous les créateurs",
  "Private(only you)": "Privé (seulement vous)",
  "Specific Designer Group": "Groupe de concepteurs spécifiques",
  "Submission Permission": "Autorisation de soumission",
  "All Users": "Tous les utilisateurs",
  "Specific User Group": "Groupe d'utilisateurs spécifique",
  "Specific Reviewers": "Examinateurs spécifiques",
  "All Reviewers": "Tous les examinateurs",
  "Permission for application tracking.": "Autorisation pour le suivi des candidatures.",
  "Design Bundle": "Ensemble de conception",
  "Workflow": "Flux de travail",
  "Bundle Name and Description is Required": "Nom du bundle et description requis",
  "Bundle Description is Required": "Description du bundle requise",
  "Bundle Create Successfully": "Bundle créé avec succès",
  "Bundle updated successfully": "Bundle mis à jour avec succès",
  "Select": "Sélectionner",
  "Unselect": "Désélectionner",
  "Applicable for Designer Roled Users only.": "Applicable uniquement aux utilisateurs avec rôle de concepteur.",
  "Applicable for Client and Reviewer Roled Users only.": "Applicable uniquement aux utilisateurs avec rôle de client et de réviseur.", "Done": "Fait",
  "Failed to update permission!": "Échec de la mise à jour de l'autorisation !",
  "Failed to fetch roles!": "Échec de la récupération des rôles !",
  "Failed to update role!": "Échec de la mise à jour du rôle !",
  "Permission updated successfully!": "Autorisation mise à jour avec succès !",
  "Role updated successfully!": "Rôle mis à jour avec succès !",
  "Update failed!": "Mise à jour a échoué!",
  "Clear": "Clair",
  "Are you sure deleting the role": "Êtes-vous sûr de supprimer le rôle",
  "Confirm Delete": "Confirmation de la suppression",
  "All roles": "Tous les rôles",
  "Filter By:": "Filtrer par:",
  "Create": "Créer",
  "Eg: Account Manager": "Ex : Gestionnaire de compte",
  "Search by name, username or email": "Rechercher par nom, nom d'utilisateur ou e-mail",
  "No data Found": "Aucune donnée disponible",
  "Roles": "les rôles",
  "Users": "utilisatrices",
  "Create New Role": "Créer un nouveau rôle",
  "Search by role name": "Rechercher par nom de rôle",
  "Role Name": "Nom de rôle",
  "Actions": "Actions",
  "Create Role": "créer un rôle",
  "Email": "Email",
  "Role": "Rôle",
  "Add Role": "Ajouter un rôle",
  "Role deleted successfully!": "Rôle supprimé avec succès!",
  "Failed to delete role!": "Échec de la suppression du rôle!",
  "Role created successfully!": "Rôle créé avec succès!",
  "Failed to create role!": "Échec de la création du rôle!",
  "Update success!": "Mise à jour réussie!",
  "No results found": "Aucun résultat trouvé",
  "Edit Role": "Modifier le rôle",
  "Failed to fetch users!": "Échec de la récupération des utilisateurs!",
  "Role names cannot contain special characters except   _ , -": "Les noms de rôle ne peuvent pas contenir de caractères spéciaux sauf _ , -",
  "Role names cannot contain special characters except _ , - , / ": "Les noms de rôle ne peuvent pas contenir de caractères spéciaux sauf _ , - , /",
  "Rules deployed successfully": "Règles déployées avec succès",
  "Choose From Template": "Choisir parmi les modèles",
  "Back to forms": "Retour aux formulaires",
  "Choose From Templates": "Choisir parmi les modèles",
  "Explore our Template Library to find the perfect combination of forms and workflows. Click to preview a template and, if it fits your needs, customize it to your liking. Once you've tailored it to your specific requirements, you're all set to create your business case": "Explorez notre Bibliothèque de modèles pour trouver la combinaison parfaite de formulaires et de workflows. Cliquez pour prévisualiser un modèle, et s'il correspond à vos besoins, personnalisez-le selon vos préférences. Une fois que vous l'avez adapté à vos besoins spécifiques, vous êtes prêt à créer votre étude de cas.",
  "All templates": "Tous les modèles",
  "Template count": "Nombre de modèles",
  "Learn more": "En savoir plus",
  "Template Details": "Détails du modèle",
  "Template Title": "Titre du modèle",
  "Preview Form": "Prévisualiser le formulaire",
  "Connected Workflow": "Workflow connecté",
  "Delete Template": "Supprimer le modèle",
  "Use Template": "Utiliser le modèle",
  "Template created successfully": "Modèle créé avec succès",
  "Template deleted successfully": "Modèle supprimé avec succès",
  "Template updated successfully": "Modèle mis à jour avec succès",
  "Category": "Catégorie",
  "Search category...": "Rechercher une catégorie...",
  "Save as Template": "Enregistrer en tant que modèle",
  "Create Template": "Créer un modèle",
  "Select or Create Category": "Sélectionner ou créer une catégorie",
  "Short Intro": "Brève introduction",
  "Enter short intro": "Saisir une brève introduction",
  "Enter description": "Saisir une description",
  "Select or create": "Sélectionner ou créer",
  "Update Template": "Mettre à jour le modèle",
  "Form created successfully": "Formulaire créé avec succès",
  "select or create": "Sélectionner ou créer",
  "Update Associated Form": "Mettre à jour le formulaire associé",
  "Update Associated Workflow": "Mettre à jour le workflow associé",
  "Enter title": "Entrer le titre",
  "No Templates Found": "Aucun modèle trouvé",
  "No Category Found": "Aucune catégorie trouvée",
  "Save Template": "Enregistrer le Modèle",
  "You can type and click the": "Vous pouvez taper et cliquer sur",
  "to create the category.": "pour créer la catégorie.",
  "select or type to create": "sélectionnez ou saisissez pour créer",
  "BPMN": "BPMN",
  "DMN": "DMN",
  "Advanced Designer": "Concepteur Avancé",
  "Basic Designer": "Concepteur de Base",
  "Search": "Rechercher",
  "Key": "Clé",
  "No Process Found": "Aucun Processus Trouvé",
  "Search workflow": "Rechercher le Flux de Travail",
  "No Dmn Found": "Aucun DMN Trouvé",
  "Submission ID": "ID de Soumission",
  "Submission Name": "Nom de Soumission",
  "Submission History": "Historique de Soumission",
  "No Submission History found": "Aucun Historique de Soumission Trouvé",
  "Error in submission count": "Erreur dans le comptage des soumissions",
  "Permission for submission tracking.": "Permission de suivi des soumissions.",
  "Reviewer permission to view submissions": "Permission du réviseur de consulter les soumissions",
  "Permission to create new submission": "Permission de créer une nouvelle soumission",
  "Back to Bundle List": "Retour à la liste des bundles",
  "Bundle": "Bundle",
  "You can drag these nodes to the pane on the right.": "Vous pouvez faire glisser ces nœuds vers le volet de droite.",
  "You can delete the element by select and click the backspace": "Vous pouvez supprimer l'élément en le sélectionnant et en cliquant sur la touche de retour",
  "Workflow Id": "Identifiant du flux de travail",
  "Enter Workflow Id": "Entrer l'identifiant du flux de travail",
  "Back to Submissions": "Retour aux soumissions",
  "Back to Drafts": "Retour aux brouillons",
  "Choose Your Workflow Option": "Choisissez votre option de flux de travail",
  "Create New Workflow": "Créer un nouveau flux de travail",
  "Choose this option when you want to create a fresh workflow based on the template. System will generate and deploy a new workflow, then connect it to the selected form. Opt for this if your form requires a distinct set of workflow steps tailored to its specific needs.": "Choisissez cette option lorsque vous souhaitez créer un nouveau flux de travail à partir du modèle. Le système générera et déploiera un nouveau flux de travail, puis le connectera au formulaire sélectionné. Optez pour cette option si votre formulaire nécessite un ensemble distinct d'étapes de flux de travail adaptées à ses besoins spécifiques.",
  "Reuse Existing Workflow": "Réutiliser un flux de travail existant",
  "Select this option to link the form with an already established workflow. Any modifications made to the existing workflow will automatically reflect in this form as well. This is ideal when you want to leverage the existing workflow for your form's requirements.": "Sélectionnez cette option pour lier le formulaire à un flux de travail déjà établi. Toutes les modifications apportées au flux de travail existant se refléteront automatiquement dans ce formulaire. C'est idéal lorsque vous souhaitez exploiter le flux de travail existant pour répondre aux besoins de votre formulaire",
  "Are you sure you wish to delete the Template?": "Êtes-vous sûr de vouloir supprimer le modèle ?",
  "Enter Workflow Name": "Saisissez le nom du flux de travail",
  "Create Workflow": "Créer un Flux de Travail",
  "Upload Workflow": "Télécharger un Flux de Travail",
  "Edit Workflow": "Modifier un Flux de Travail",
  "Published": "Publié",
  "Unpublished": "Non Publié",
  "Private": "Privé",
  "Anonymous": "Anonyme",
  "Edit Processes": "Modifier les Processus",
  "Create Processes": "Créer des Processus",
  "tasks": "tâches",
  "Submission Id": "ID de soumission",
  "Clear All Filters": "Effacer tous les filtres",
  "Show results": "Afficher les résultats",
  "Delete Draft": "Supprimer le brouillon",
  "I can help you design forms effortlessly- all you have to do is give me a clear and concise description of what the form is meant to do. I will build out the fields and layout for you,which you can further customize.": "Je peux vous aider à concevoir des formulaires sans effort - tout ce que vous avez à faire est de me donner une description claire et concise de ce que le formulaire est censé faire. Je vais construire les champs et la mise en page pour vous, que vous pouvez personnaliser davantage.",
  "I am": "Je suis",
  "For example 'Give us a form that will serve as a patient intake form for walk-in clinics'": "Par exemple 'Donnez-nous un formulaire qui servira de formulaire d'admission pour les cliniques sans rendez-vous'.",
  "Enter your prompt here": "Entrez votre invite ici",
  "Would you like to continue?": "Souhaitez-vous continuer ?",
  "Restart": "Redémarrer",
  "I'm here to provide further assistance. Thank you!": "Je suis ici pour fournir une assistance supplémentaire. Merci !",
  "Generate Form Design": "Générer la conception du formulaire",
  "Preview": "prévisualisation",
  "Your form is now ready!": "Votre formulaire est maintenant prêt !",
  "Please tell me all the specifications and requirements you have in mind for your form. After you have finished the description, hit send.": "Veuillez nous donner toutes les spécifications et exigences que vous avez en tête pour votre formulaire. Après avoir terminé la description, appuyez sur Envoyer.",
  "Choose template": "Choisir un modèle",
  "Search by bundle name": "Rechercher par nom de bundle",
  "Save Bundle": "Enregistrer Bundle",
  "Id": "Id",
  "Enable bundling ?": "Activer le regroupement ?",
  "Select a task in the List.": "Sélectionnez une tâche dans la liste.",
  "Add Date": "Ajouter une date",
  "Assign to Me": "M'attribuer",
  "Filter count:": "Nombre de filtres :",
  "Selected Forms": "Formulaires sélectionnés",
  "Template Used:": "Modèle utilisé:",
  "Select a task from the List.": "Sélectionnez une tâche dans la liste.",
  "Choose a task from the list; your selection directs your current activity.": "Choisissez une tâche dans la liste ; votre sélection oriente votre activité actuelle.",
  "Please change the selected filters to view submissions": "Veuillez changer les filtres sélectionnés pour voir les soumissions",
  "Please change the selected filters to view drafts": "Veuillez changer les filtres sélectionnés pour voir les brouillons",
  "No drafts found": "Aucun brouillon trouvé",
  "Flow-E helps to build faster": "Flow-E aide à construire plus rapidement",
  "Flow-E is not available for demonstration in the trial instance. You can experience Flow-E in any of the premium packages of the product": "Flow-E n'est pas disponible pour une démonstration dans l'instance d'essai. Vous pouvez découvrir Flow-E dans l'un des packages premium du produit",
  "Get in touch with formsflow.ai team": "Contactez l'équipe de formsflow.ai",
  "Premium": "Premium"
};










