/* eslint-disable max-len */
export const RESOURCE_BUNDLES_DE = {
  "Submit Form": "Formular absenden",
  "Changes Applied Successfully": "Änderungen erfolgreich angewendet",
  "Filter Deleted Successfully": "Filter erfolgreich gelöscht",
  "Save Filter": "Filter speichern",
  "Filter Name": "Filtername",
  "Create new filter": "Neuen Filter erstellen",
  "Definition Key": "Definitionsschlüssel",
  "Permission": "Berechtigung",
  "Enter your text here": "Geben Sie hier Ihren Text ein",
  "Accessible for all users": "Zugänglich für alle Benutzer",
  "Private (Only You)": "Privat (Nur Sie)",
  "Specific Group": "Spezifische Gruppe",
  "Select Elements": "Elemente auswählen",
  " Task Attributes Selected": " Ausgewählte Aufgabenattribute",
  "Delete Filter": "Filter löschen",
  "Add Value": "Wert hinzufügen",
  "Show only current user assigned task": "Nur zugewiesene Aufgaben des aktuellen Benutzers anzeigen",
  "Show task based on logged user roles": "Aufgaben basierend auf den Rollen des angemeldeten Benutzers anzeigen",
  "Create Filter": "Filter erstellen",
  "Task Attributes": "Aufgabenattribute",
  "Only selected task attributes will be available on task list view": "Nur ausgewählte Aufgabenattribute sind in der Aufgabenliste sichtbar",
  "Show undefined variables": "Undefinierte Variablen anzeigen",
  "Label": "Beschriftung",
  "Variables": "Variablen",
  "Task Title": "Aufgabentitel",
  "List View": "Listenansicht",
  "Card View": "Kartenansicht",
  "Create New Filter": "Neuen Filter erstellen",
  "Sort by": "Sortieren nach",
  "Collapse All": "Alle minimieren",
  "Expand All": "Alle maximieren",
  "Application Id": "Anwendungs-ID",
  "Back to task": "Zurück zur Aufgabe",
  "Filters": "Filter",
  "Assignee": "Beauftragter",
  "Select a user": "Benutzer auswählen",
  "Create DMN": "DMN erstellen",
  "Create Process": "Prozess erstellen",
  "Edit DMN": "DMN bearbeiten",
  "More options": "Mehr Optionen",
  "Form Description": "Formularbeschreibung",
  "Saved to Submissions/Drafts": "Gespeichert in Einreichungen/Entwürfe",
  "Unfinished submissions will be saved to Submissions/Drafts.": "Unvollständige Einreichungen werden in Einreichungen/Entwürfe gespeichert.",
  "Edit Process": "Prozess bearbeiten",
  "Add filter name": "Filternamen hinzufügen",
  "This section is aimed to set the parameters used to filter the tasks": "Dieser Abschnitt soll die Parameter festlegen, die zur Filterung der Aufgaben verwendet werden",
  "This section is aimed to set read permissions for the filter": "Dieser Abschnitt soll Leseberechtigungen für den Filter festlegen",
  "This section is aimed to set select task attributes that will be visible in the task list view": "Dieser Abschnitt soll auswählbare Aufgabenattribute festlegen, die in der Aufgabenliste sichtbar sein werden",
  "Task Attribute": "Aufgabenattribut",
  "You can define variables shown in the list": "Sie können Variablen definieren, die in der Liste angezeigt werden",
  "results": "Ergebnisse",
  "Enter role name": "Rollenname eingeben",
  "Public": "Öffentlich",
  "View Submitted Form": "Eingereichtes Formular anzeigen",
  "View Draft Details": "Entwurfsdetails anzeigen",
  "Edit Draft": "Entwurf bearbeiten",
  "Draft Title": "Titel des Entwurfs",
  "Filter": "Filter",
  "Advanced Options": "Erweiterte Optionen",
  "Enter the path name": "Geben Sie den Pfadnamen ein",
  "Search by form title": "Nach Formulartitel suchen",
  "Associate Workflow": "Workflow verknüpfen",
  "Search by workflow name": "Nach Workflow-Namen suchen",
  "View Details": "Details anzeigen",
  "Visibility": "Sichtbarkeit",
  "Form Title": "Formulartitel",
  "Access restricted by its designer..!": "Zugriff durch den Designer eingeschränkt..!",
  "and you will no longer have the access to this form in bundles": "und Sie haben keinen Zugriff mehr auf dieses Formular in Bündeln",
  "You will no longer have the access to this form in bundles": "Sie haben keinen Zugriff mehr auf dieses Formular in Bündel",
  "Bad Request": "Ungültige Anforderung",
  "Select the form to be displayed based on the  above criteria": "Wählen Sie das anzuzeigende Formular basierend auf den oben genannten Kriterien aus.",
  "Create the criteria using the API key of any form component and a conditional notation. For example, 'age > 18' where 'age' is the API key of a form component": "Erstellen Sie die Kriterien unter Verwendung des API-Schlüssels eines beliebigen Formularkomponenten und einer bedingten Notation. Zum Beispiel 'age > 18', wobei 'age' der API-Schlüssel eines Formularkomponenten ist.",
  "Select form version": "Formularversion auswählen",
  "Please remove the special characters...!": "Bitte entfernen Sie die Sonderzeichen...!",
  "No histories found": "Keine Historien gefunden",
  "Continue": "Weitermachen",
  "This will create a new version of the form without changing the path name but with new form id.": "Dadurch wird eine neue Version des Formulars erstellt, ohne den Pfadnamen zu ändern, aber mit einer neuen Formular-ID.",
  "Save as a new version": "Als neue Version speichern",
  "Save New Version": "Neue Version speichern",
  "Do you want to save a new version of this form?": "Möchten Sie eine neue Version dieses Formulars speichern?",
  "Do you want to create a duplicate form and associated workflow from existing form ?": "Möchten Sie ein Duplikat des Formulars und des zugehörigen Workflows aus einem vorhandenen Formular erstellen?",
  "New version created": "Neue Version erstellt",
  "Create a duplicate form": "Erstellen Sie ein Duplikat des Formulars",
  "Show more": "Zeig mehr",
  "Modified By": "Verändert von",
  "Created On": "Erstellt am",
  "Formsflow automatically saves your previous form data. Now you can switch to the previous stage and edit.": "Formsflow speichert automatisch Ihre vorherigen Formulardaten. Jetzt können Sie zur vorherigen Stufe wechseln und bearbeiten.",
  "Duplicate Form": "Doppeltes Formular",
  "Form History": "Formulargeschichte",
  "No file chosen": "Keine Datei ausgewählt",
  "Choose File": "Datei wählen",
  "Choose any": "Wählen Sie eine aus",
  "Sort by form name": "Nach Formularnamen sortieren",
  "select form type": "Formulartyp auswählen",
  "Draft Deleted Successfully": "Entwurf erfolgreich gelöscht",
  "Saved to Applications/Drafts": "Unter Anwendungen/Entwürfe gespeichert",
  "Unfinished applications will be saved to Applications/Drafts.": " Unfertige Bewerbungen werden unter Bewerbungen/Entwürfe gespeichert.",
  "Discard Draft": "Entwurf verwerfen",
  "with ID": "mit Ausweis",
  "Please change the selected filters to view Forms": "Bitte ändern Sie die ausgewählten Filter, um Formulare anzuzeigen",
  "Search by form name": "Suche nach Formularnamen",
  "Click to search": "Klicken Sie, um zu suchen",
  "No submissions found": "Keine Einreichungen gefunden",
  "No submission avaliable in the selected date. Please select another date range": "Für das ausgewählte Datum ist keine Einreichung verfügbar. Bitte wählen Sie einen anderen Zeitraum aus",
  "Form field": "Formularfeld",
  "Enter Label": "Bezeichnung eingeben",
  "Select form field": "Formularfeld auswählen",
  "Add form fields to display in task list": "Fügen Sie Formularfelder zur Anzeige in der Aufgabenliste hinzu",
  "Latest Version": "Letzte Version",
  "Submission Status": "Submission Status",
  "No submission status": "Kein Übermittlungsstatus",
  "No Public url found": "Keine öffentliche URL gefunden",
  "Show in list": "In Liste anzeigen",
  "Select Task Variable": "Wählen Sie Aufgabenvariable aus",
  "Enter your Label": "Geben Sie Ihr Etikett ein",
  "Please select from one of the following workflows.": "Bitte wählen Sie einen der folgenden Workflows aus.",
  "Select Key": "Wählen Sie Schlüssel",
  "Create Your Task Variable": "Erstellen Sie Ihre Aufgabenvariable",
  "No task variable selected": "Keine Aufgabenvariable ausgewählt",
  "Workflow Associate": "Workflow-Mitarbeiter",
  "Task variable": "Aufgabenvariable",
  "Enter pathname": "Geben Sie den Pfadnamen ein",
  "Name": "Name",
  "Title": "Titel",
  "Display as": "Darstellen als",
  "Anonymous Form ": "Anonymes Formular",
  "Submission Saved": "Einreichung gespeichert.",
  "Error while Submission.": "Fehler beim Senden.",
  "Form saved": "Formular gespeichert.",
  "Message": "Bitte beheben Sie die Fehler, bevor Sie sie erneut senden.",
  "Submission cannot be done.": "Eine Abgabe ist nicht möglich.",
  "No Process Diagram found": "Kein Prozessdiagramm gefunden",
  "Edit": "Bearbeiten",
  "Do you want to associate form with a workflow ?": "Möchten Sie ein Formular mit einem Workflow verknüpfen?",
  "Yes": "Jawohl",
  "No": "Nein",
  "Form Name": "Formularname ",
  "Workflow Name": "Workflow-Name",
  "Publish this form for Client Users.": "Veröffentlichen Sie dieses Formular für Clientbenutzer.",
  "Comments": "Bemerkungen",
  "Back": "Zurück",
  "Edit Form": "Formular bearbeiten",
  "Next": "Nächste",
  "Save": "Speichern",
  "Back to Form List": "Zurück zur Formularliste",
  "Submit New": "Neu einreichen",
  "View Submissions": "Einsendungen anzeigen",
  "View/Edit Form": "Formular anzeigen/bearbeiten",
  "Delete Form": "Formular löschen",
  "Delete": "Löschen",
  "Print As PDF": "Als PDF drucken",
  "Applications": "Anwendungen",
  "Tasks": "Aufgaben",
  "Metrics": "Metriken",
  "Dashboard": "Armaturenbrett",
  "Dashboards": "Dashboards",
  "Logout": "Ausloggen",
  "No tasks found": "Keine Aufgaben gefunden",
  "Please change the selected filters to view tasks": "Bitte ändern Sie die ausgewählten Filter, um Aufgaben anzuzeigen",
  "Clear all filters": "Alle Filter löschen",
  "Created": "Erstellt",
  "Task Variables": "Task-Variablen",
  "Process Variables": "Prozessvariablen",
  "Process Definition Name": "Name der Prozessdefinition",
  "Assigne": "Abtretungsempfängerin",
  "Candidate Group": "Kandidatengruppe",
  "Candidate User": "Kandidatenbenutzer",
  "Description": "Beschreibung",
  "Priority": "Priorität",
  "Due Date": "Geburtstermin",
  "Follow up Date": "Follow-up-Termin",
  "Task name": "Aufgabennname",
  "Remove sorting": "Sortierung entfernen",
  "Ascending": "Aufsteigend",
  "Descending": "Absteigend",
  "Add sorting": "Sortierung hinzufügen",
  "of the criteria are met.": "der Kriterien erfüllt sind.",
  "For Variable, ignore case of": "Ignorieren Sie für Variable die Groß- und Kleinschreibung",
  "name": "Name",
  "value": "Wert",
  "No task matching filters found.": "Keine Aufgabe gefunden, die mit Filtern übereinstimmt.",
  "Application ID": "Anwendungs-ID",
  "Set follow-up Date": "Folgedatum festlegen",
  "Set Due date": "Fälligkeitsdatum festlegen",
  "Add groups": "Gruppen hinzufügen",
  "Claim": "Beanspruchen",
  "Search By Last Name": "Suche nach Nachnamen",
  "Search By First Name": "Suche nach Vornamen",
  "Search By Email": "Per E-Mail suchen",
  "You can add a group by typing a group ID into the input field and afterwards clicking the button with the plus sign.": "Sie können eine Gruppe hinzufügen, indem Sie eine Gruppen-ID in das Eingabefeld eingeben und anschließend auf die Schaltfläche mit dem Pluszeichen klicken.",
  "Add a group": "Gruppe hinzufügen",
  "Process Name": "Prozessname",
  "Set FollowUp Date": "Nachsorgedatum festlegen",
  "Groups": "Gruppen",
  "Click to Change Assignee": "Klicken Sie auf Zugewiesenen ändern",
  "Reset Assignee": "Zugewiesenen zurücksetzen",
  "Update User": "Benutzer aktualisieren",
  "Close": "Nah dran",
  'Type': "Art",
  "Remove search": "Suche entfernen",
  "Property": "Eigentum",
  "Operator": "Operatorin",
  "Total number of results": "Gesamtzahl der Ergebnisse",
  "Filter Tasks": "Aufgaben filtern",
  "All": "Alle",
  "ANY": "IRGENDEIN",
  "Task assignee": "Aufgabenzuständiger",
  "Application History": "Anwendungsgeschichte",
  "View Submission": "Einreichung anzeigen",
  "Showing": "Anzeigen",
  "to": "zu",
  "Results": "Ergebnisse",
  "Acknowledge": "Anerkennen",
  "View": "Sicht",
  "Select a task in the list.": "Wählen Sie eine Aufgabe in der Liste aus.",
  "No Filters Found": "Keine Filter gefunden",
  "Loading": "Wird geladen",
  "Form": "Bilden",
  "History": "Geschichte",
  "Diagram": "Diagramm",
  "Delete Confirmation": "Bestätigung löschen",
  "All tasks": "Alle Aufgaben",
  "Manage Groups": "Gruppen verwalten",
  "Group ID": "Gruppen-ID",
  "Due": "Fällig",
  "Follow-up": "Nachverfolgen",
  "Application Name": "Anwendungsname",
  "Created By": "Erstellt von",
  "Application Status": "Bewerbungsstatus",
  "Request Status": "Anforderungsstatus",
  "Request Name": "Name anfordern",
  "Submitted On": "Eingereicht am",
  "Modified On": "Geändert am",
  "No History Found": "Kein Verlauf gefunden",
  "Process Diagram": "Prozessdiagramm",
  "Details": "Einzelheiten",
  "Status": "Status",
  "Submissions": "Submissions",
  "No Application History found": "Keine Anwendungshistorie gefunden",
  "Link To Form Submission": "Link zur Formularübermittlung",
  "Last Modified": "Zuletzt geändert",
  "I": "Einblicke",
  "No dashboard found": "Kein Dashboard gefunden",
  "of": "von",
  "Select Dashboard": "Wählen Sie Dashboard aus",
  "Total Submissions": "Einreichungen insgesamt",
  "Save & Preview": "Speichern & Vorschau",
  "Operations": "Operationen",
  "Access Groups": "Zugriffsgruppen",
  "Action": "Aktion",
  "Add": "Addieren",
  "Admin": "Administrator",
  "Create Form": "Formular erstellen",
  "Upload Form": "Formular hochladen",
  "Download Form": "Formular herunterladen",
  "Upload json form only": "Laden Sie nur das JSON-Formular hoch",
  "Select atleast one form": "Wählen Sie mindestens ein Formular aus",
  "Select All": "Wählen Sie Alle",
  "Created Date": "Erstellungsdatum",
  "Modified Date": "Geändertes Datum",
  "File Upload Status": "Datei-Upload-Status",
  "Forms Completed": "Formulare ausgefüllt",
  "Form Completed": "Formular ausgefüllt",
  "No forms found": "Keine Formulare gefunden",
  "Powered by ": "Bereitgestellt von ",
  "Press Enter key or click here to search": "Drücken Sie die Eingabetaste oder klicken Sie hier, um zu suchen",
  "Click to clear": "Zum Löschen klicken",
  "Search...": "Suche...",
  "No applications found": "Keine Anwendungen gefunden",
  "Successfully Updated": "Erfolgreich aktualisiert",
  "Failed": "Gescheitert",
  "Submission Failed.": "Übermittlung fehlgeschlagen.",
  "Click here to back": "Klicken Sie hier, um zurück zu gelangen",
  "path": "Weg",
  "Make this form public ?": "Dieses Formular öffentlich machen?",
  "Design Form": "Entwurfsformular",
  "Associate this form with a workflow?": "Dieses Formular einem Workflow zuordnen?",
  "Preview and Confirm": "Vorschau und bestätigen",
  "All steps completed - you're finished": "Alle Schritte abgeschlossen - Sie sind fertig",
  "Enter the form machine name": "Geben Sie den Namen der Formularmaschine ein",
  "Enter the form title": "Geben Sie den Formulartitel ein",
  'Wizard': "Magier",
  'Text Field': "Textfeld",
  "example": "Beispiel",
  "Forms": "Formen",
  "Downloaded Successfully": "Erfolgreich heruntergeladen",
  "Error in JSON file structure": "Fehler in der JSON-Dateistruktur",
  "Please fix the errors before submitting again.": "Bitte beheben Sie die Fehler, bevor Sie sie erneut senden.",
  "Loading...": "Wird geladen...",
  "Save Form": "Formular speichern",
  "Confirm": "Bestätigen",
  "Cancel": "Stornieren",
  "Please change the selected filters to view applications": "Wijzig de geselecteerde filters om applicaties te bekijken",
  "No submissions available for the selected date range": "Für den ausgewählten Zeitraum sind keine Einreichungen verfügbar",
  "OK": "OK",
  "Form not available": "Formular nicht verfügbar",
  "Thank you for your response.": "Danke für Ihre Antwort.",
  "saved successfully": "Erfolgreich gespeichert",
  "Copy anonymous form URL": "Kopieren Sie die anonyme Formular-URL",
  "Submitted By": "Eingereicht von",
  "Confirmation": "Bestätigung",
  "Changing the form title will not affect the existing applications. It will only update in the newly created applications. Press Save Changes to continue or cancel the changes.": "Das Ändern des Formulartitels wirkt sich nicht auf die bestehenden Anwendungen aus. Es wird nur in den neu erstellten Anwendungen aktualisiert. Drücken Sie Änderungen speichern, um fortzufahren oder die Änderungen abzubrechen.",
  "Error while saving Form": "Fehler beim Speichern des Formulars",
  "Click here to go back": "Klicke hier um zurück zu kommen",
  " Applications are submitted against": " Anträge werden dagegen gestellt",
  " Application is submitted against": " Antrag wird dagegen gestellt",
  "Are you sure to delete the": "Sind Sie sicher, dass Sie löschen möchten",
  "Are you sure to delete the draft": "Sind Sie sicher, dass Sie den Entwurf löschen möchten",
  " Submissions are made against": " Einreichungen erfolgen gegen",
  " Submission is made against": " Die Einreichung erfolgt gegen",
  "Are you sure to delete this submission ?": "Sind Sie sicher, dass Sie diesen Eintrag löschen möchten?",
  "Save Changes": "Save Changes",
  "Changing the form workflow will not affect the existing applications. It will only update in the newly created applications. Press Save Changes to continue or cancel the changes.": "Das Ändern des Formular-Workflows wirkt sich nicht auf die vorhandenen Anwendungen aus. Es wird nur in den neu erstellten Anwendungen aktualisiert. Drücken Sie Änderungen speichern, um fortzufahren oder die Änderungen abzubrechen.",
  "Form-Workflow association saved.":
    "Formular-Workflow-Zuordnung gespeichert.",
  "Form-Workflow association failed.":
    "Die Formular-Workflow-Zuordnung ist fehlgeschlagen.",
  "Form deleted successfully": "Formular erfolgreich gelöscht",
  "Bundle deleted successfully": "Bundle erfolgreich gelöscht",
  "Form deletion unsuccessful": "Formularlöschung fehlgeschlagen",
  "Form process failed": "Formularprozess fehlgeschlagen",
  "Form unpublishing Failed": "Das Aufheben der Veröffentlichung des Formulars ist fehlgeschlagen",
  "All groups have access to the dashboard": "Alle Gruppen haben Zugriff auf das Dashboard",
  "Export PDF": "PDF exportieren",
  "Something went wrong. Please try again!": "Etwas ist schief gelaufen. Bitte versuche es erneut!",
  "No Dashboards Found": "Keine Dashboards gefunden",
  "Processes": "Prozesse",
  "Please select an existing workflow.": "Bitte wählen Sie einen bestehenden Workflow aus.",
  "Create New": "Erstelle neu",
  "Or create new workflow or import a workflow from a local directory.": "Oder erstellen Sie einen neuen Workflow oder importieren Sie einen Workflow aus einem lokalen Verzeichnis.",
  "The operation couldn't be completed. Please try after sometime": "Der Vorgang konnte nicht abgeschlossen werden. Bitte versuchen Sie es nach einiger Zeit erneut",
  "Select...": "Auswählen...",
  "No Drafts Found": "Keine Entwürfe gefunden",
  "Help": "Hilfe",
  "Draft Id": "Entwurfs-ID",
  "Draft Name": "Entwurfsname",
  "Link To Draft Submission": "Link zur Entwurfsübermittlung",
  "Drafts": "Entwürfe",
  "You can't submit this form until it is published": "Sie können dieses Formular erst absenden, wenn es veröffentlicht wurde",
  "Form not published": "Formular nicht veröffentlicht",
  "Access Denied": "Zugriff abgelehnt",
  "By default, the tenant key would be prefixed to form": "Standardmäßig wird dem Mandantenschlüssel form vorangestellt",
  "go back": "geh zurück",
  "No Applications Found": "Keine Anwendungen gefunden",
  "Submission saved to draft.": "Übermittlung als Entwurf gespeichert.",
  "Saved to draft": "Als Entwurf gespeichert",
  "Saving...": "Sparen...",
  "Resource": "Ressource",
  "Error in creating form process mapper": "Fehler beim Erstellen des Formularprozess-Mappers",
  "Select the date": "Wählen Sie das Datum aus",
  "Diagram deployed": "Diagramm bereitgestellt",
  "Deployment failed": "Bereitstellung fehlgeschlagen",
  "form uploaded": "Formular hochgeladen",
  "forms uploaded": "Formulare hochgeladen",
  "Failed to upload": "Hochladen fehlgeschlagen",
  "forms...!": "Formen...!",
  "form...!": "bilden...!",
  "Process name(s) must not be empty": "Prozessname(n) dürfen nicht leer sein",
  "URL copied": "URL kopiert",
  "Click Here to Copy": "Klicken Sie hier, um zu kopieren",
  "Resources": "Bronnen",
  "Duplicate form created successfully": "Doppeltes Formular erfolgreich erstellt",
  "Revert": "Zurückkehren",
  "Overview": "Überblick",
  "Design Permission": "Designgenehmigung",
  "Application Permission": "Anwendungserlaubnis",
  "All Designers": "Alle Designer",
  "Private(only you)": "Privat (nur Sie)",
  "Specific Designer Group": "Spezifische Designergruppe",
  "Submission Permission": "Einreichungserlaubnis",
  "All Users": "Alle Nutzer",
  "Specific User Group": "Spezifische Benutzergruppe",
  "Specific Reviewers": "Spezifische Gutachter",
  "All Reviewers": "Alle Rezensenten",
  "Permission for application tracking.": "Erlaubnis zur Bewerbungsverfolgung.",
  "Applicable for Designer Roled Users only.": "Gilt nur für Benutzer mit Designer-Rolle.",
  "Applicable for Client and Reviewer Roled Users only.": "Gilt nur für Benutzer mit Kunden- und Prüferrollen.",
  "Criteria": "Kriterien",
  "Select Form": "Wählen Sie Formular aus",
  "Create Condition": "Bedingung erstellen",
  "Enter criteria": "Kriterien eingeben",
  "Make this form public": "Machen Sie dieses Formular öffentlich",
  "Enable bundling": "Bündelung aktivieren",

  "Bundle Name": "Bündelname",
  "View/Edit": "Anzeigen/Bearbeiten",
  "Create Bundle": "Bündel erstellen",
  "Rows per page": "Zeilen pro Seite",
  "Items per page": "Elemente pro Seite",
  "entries": "Einträge",
  "Form Bundle": "Formularbündel",
  "No bundles found": "Keine Pakete gefunden",
  "Please change the selected filters to view Bundles": "Bitte ändern Sie die ausgewählten Filter, um Bundles anzuzeigen",
  "Select all to download forms": "Wählen Sie alle aus, um Formulare herunterzuladen",
  "Select for download": "Zum Herunterladen auswählen",
  "Bundle Description": "Bündelbeschreibung",
  "Conditions": "Bedingungen",
  "Enter name": "Name eingeben",
  "Enter Description": "Beschreibung eingeben",
  "You can change the form order by drag and drop": "Sie können die Formularreihenfolge per Drag and Drop ändern",
  "Form Order": "Bestellformular",
  "Form Type": "Formulartyp",
  "Add Forms Launch together": "Fügen Sie Forms Launch zusammen hinzu",
  "Form bundles can save your time by grouping forms together": "Formularbündel können Ihre Zeit sparen, indem Sie Formulare gruppieren",
  "Add Forms": "Formulare hinzufügen",
  "Create Conditions": "Bedingungen schaffen",
  " Add Conditions": "Bedingungen hinzufügen",
  "Select Forms": "Wählen Sie Formulare aus",
  "Insert": "Einfügung",
  "View Form": "Formular anzeigen",
  "Publish this Bundle for Client Users.": "Veröffentlichen Sie dieses Bundle für Client-Benutzer",
  "Total Forms": "Gesamtformen",
  "Edit Bundle": "Bündel bearbeiten",
  "Next Form": "Nächstes Formular",
  "Previous Form": "Vorheriges Formular",
  "Forms included under the package": "Formulare im Paket enthalten",
  "Submit": "einreichen",
  "Reset Zoom": "Zoom zurücksetzen",
  "Zoom In": "Hineinzoomen",
  "Zoom Out": "Rauszoomen",
  "Deploy": "Einsetzen",
  "Export": "Export",
  "Design Bundle": "Design-Paket",
  "Workflow": "Arbeitsablauf",
  "Bundle Name and Description is Required": "Paketname und -beschreibung sind erforderlich",
  "Bundle Description is Required": "Paketbeschreibung ist erforderlich",
  "Bundle Create Successfully": "Paket erfolgreich erstellt",
  "Bundle updated successfully": "Paket erfolgreich aktualisiert",
  "Select": "Auswählen",
  "Unselect": "Abwählen",
  "Done": "Erledigt",
  "Failed to update permission!": "Berechtigung konnte nicht aktualisiert werden!",
  "Failed to fetch roles!": "Rollen konnten nicht abgerufen werden!",
  "Failed to update role!": "Rolle konnte nicht aktualisiert werden!",
  "Permission updated successfully!": "Berechtigung erfolgreich aktualisiert!",
  "Role updated successfully!": "Rolle erfolgreich aktualisiert!",
  "Update failed!": "Update fehlgeschlagen!",
  "Clear": "Klar",
  "Are you sure deleting the role": "Sind Sie sicher, dass Sie die Rolle löschen",
  "Confirm Delete": "Löschen bestätigen",
  "All roles": "Alle Rollen",
  "Filter By:": "Filtern nach:",
  "Create": "Erstellen",
  "Eg: Account Manager": "Beispiel: Kontomanager",
  "Search by name, username or email": "Suche nach Name, Benutzername oder E-Mail",
  "No data Found": "Keine Daten gefunden",
  "Roles": "Rollen",
  "Users": "Benutzer",
  "Create New Role": "Neue Rolle erstellen",
  "Search by role name": "Suche nach Rollennamen",
  "Role Name": "Rollenname",
  "Actions": "Aktionen",
  "Create Role": "Rolle erstellen",
  "Email": "Email",
  "Role": "Rolle",
  "Add Role": "Rolle hinzufügen",
  "Role deleted successfully!": "Rolle erfolgreich gelöscht!",
  "Failed to delete role!": "Rolle konnte nicht gelöscht werden!",
  "Role created successfully!": "Rolle erfolgreich erstellt!",
  "Failed to create role!": "Rolle konnte nicht erstellt werden!",
  "Update success!": "Update-Erfolg!",
  "No results found": "keine Ergebnisse gefunden",
  "Edit Role": "Rolle bearbeiten",
  "Failed to fetch users!": "Nutzer konnten nicht abgerufen werden!",
  "Role names cannot contain special characters except   _ , -": "Rollennamen dürfen keine Sonderzeichen außer _ , - enthalten.",
  "Role names cannot contain special characters except _ , - , / ": "Rollennamen dürfen keine Sonderzeichen außer _ , - , / enthalten.",
  "Rules deployed successfully": "Regeln erfolgreich bereitgestellt",
  "Choose From Template": "Aus Vorlage auswählen",
  "Back to forms": "Zurück zu den Formularen",
  "Choose From Templates": "Aus Vorlagen auswählen",
  "Explore our Template Library to find the perfect combination of forms and workflows. Click to preview a template and, if it fits your needs, customize it to your liking. Once you've tailored it to your specific requirements, you're all set to create your business case": "Erkunden Sie unsere Vorlagenbibliothek, um die perfekte Kombination aus Formularen und Workflows zu finden. Klicken Sie auf eine Vorlage, um eine Vorschau anzuzeigen, und passen Sie sie nach Ihren Wünschen an. Sobald Sie sie an Ihre speziellen Anforderungen angepasst haben, sind Sie bereit, Ihr Geschäftsszenario zu erstellen",
  "All templates": "Alle Vorlagen",
  "Template count": "Anzahl der Vorlagen",
  "Learn more": "Erfahren Sie mehr",
  "Template Details": "Vorlagendetails",
  "Template Title": "Vorlagentitel",
  "Preview Form": "Formularvorschau",
  "Connected Workflow": "Verbundener Workflow",
  "Delete Template": "Vorlage löschen",
  "Use Template": "Vorlage verwenden",
  "Template created successfully": "Vorlage erfolgreich erstellt",
  "Template deleted successfully": "Vorlage erfolgreich gelöscht",
  "Template updated successfully": "Vorlage erfolgreich aktualisiert",
  "Category": "Kategorie",
  "Search category...": "Kategorie durchsuchen...",
  "Save as Template": "Als Vorlage speichern",
  "Create Template": "Vorlage erstellen",
  "Select or Create Category": "Kategorie auswählen oder erstellen",
  "Short Intro": "Kurze Einleitung",
  "Enter short intro": "Kurze Einleitung eingeben",
  "Enter description": "Beschreibung eingeben",
  "Select or create": "Auswählen oder erstellen",
  "Update Template": "Vorlage aktualisieren",
  "Form created successfully": "Formular erfolgreich erstellt",
  "select or create": "Auswählen oder erstellen",
  "Update Associated Form": "Verknüpftes Formular aktualisieren",
  "Update Associated Workflow": "Verknüpften Workflow aktualisieren",
  "Enter title": "Titel eingeben",
  "No Templates Found": "Keine Vorlagen gefunden",
  "No Category Found": "Keine Kategorie gefunden",
  "Save Template": "Vorlage speichern",
  "You can type and click the": "Sie können tippen und klicken Sie auf die",
  "to create the category.": "um die Kategorie zu erstellen.",
  "select or type to create": "auswählen oder eingeben, um zu erstellen",
  "BPMN": "BPMN",
  "DMN": "DMN",
  "Advanced Designer": "Fortgeschrittener Designer",
  "Basic Designer": "Grundlegender Designer",
  "Search": "Suchen",
  "Key": "Schlüssel",
  "No Process Found": "Kein Prozess Gefunden",
  "Search workflow": "Workflow Suchen",
  "No Dmn Found": "Kein DMN Gefunden",
  "Submission ID": "Einreichungs-ID",
  "Submission Name": "Einreichungsname",
  "Submission History": "Einreichungshistorie",
  "No Submission History found": "Keine Einreichungshistorie gefunden",
  "Error in submission count": "Fehler in der Einreichungszählung",
  "Permission for submission tracking.": "Berechtigung für die Einreichungsverfolgung.",
  "Reviewer permission to view submissions": "Berechtigung des Reviewers zur Ansicht von Einreichungen",
  "Permission to create new submission": "Berechtigung zur Erstellung einer neuen Einreichung",
  "Back to Bundle List": "Zurück zur Bündelliste",
  "Bundle": "Bündel",
  "You can drag these nodes to the pane on the right.": "Sie können diese Knoten auf das Fenster rechts ziehen.",
  "You can delete the element by select and click the backspace": "Sie können das Element auswählen und die Rücktaste drücken, um es zu löschen",
  "Workflow Id": "Workflow-ID",
  "Enter Workflow Id": "Workflow-ID eingeben",
  "Back to Submissions": "Zurück zu den Einreichungen",
  "Back to Drafts": "Zurück zu den Entwürfen",
  "Choose Your Workflow Option": "Wählen Sie Ihre Workflow-Option",
  "Create New Workflow": "Neuen Workflow erstellen",
  "Choose this option when you want to create a fresh workflow based on the template. System will generate and deploy a new workflow, then connect it to the selected form. Opt for this if your form requires a distinct set of workflow steps tailored to its specific needs.": "Wählen Sie diese Option aus, wenn Sie einen neuen Workflow auf der Grundlage der Vorlage erstellen möchten. Das System generiert und implementiert einen neuen Workflow und verbindet ihn dann mit dem ausgewählten Formular. Entscheiden Sie sich dafür, wenn Ihr Formular eine speziell auf seine Bedürfnisse zugeschnittene Reihe von Workflow-Schritten erfordert.",
  "Reuse Existing Workflow": "Vorhandenen Workflow wiederverwenden",
  "Select this option to link the form with an already established workflow. Any modifications made to the existing workflow will automatically reflect in this form as well. This is ideal when you want to leverage the existing workflow for your form's requirements.": "Wählen Sie diese Option aus, um das Formular mit einem bereits etablierten Workflow zu verknüpfen. Alle Änderungen am vorhandenen Workflow werden sich automatisch auch in diesem Formular widerspiegeln. Dies ist ideal, wenn Sie den vorhandenen Workflow für die Anforderungen Ihres Formulars nutzen möchten.",
  "Are you sure you wish to delete the Template?": "Sind Sie sicher, dass Sie die Vorlage löschen möchten?",
  "Enter Workflow Name": "Geben Sie den Workflow-Namen ein",
  "Create Workflow": "Workflow erstellen",
  "Upload Workflow": "Workflow hochladen",
  "Edit Workflow": "Workflow bearbeiten",
  "Published": "Veröffentlicht",
  "Unpublished": "Nicht veröffentlicht",
  "Private": "Privat",
  "Anonymous": "Anonym",
  "Edit Processes": "Prozesse bearbeiten",
  "Create Processes": "Prozesse erstellen",
  "tasks": "Aufgaben",
  "Submission Id": "Einsende-ID",
  "Clear All Filters": "Alle Filter löschen",
  "Show results": "Ergebnisse anzeigen",
  "Delete Draft": "Entwurf löschen",
  "I can help you design forms effortlessly- all you have to do is give me a clear and concise description of what the form is meant to do. I will build out the fields and layout for you,which you can further customize.": "Ich kann Ihnen helfen, Formulare mühelos zu gestalten - alles, was Sie tun müssen, ist mir eine klare und prägnante Beschreibung dessen zu geben, wofür das Formular gedacht ist. Ich werde die Felder und das Layout für Sie erstellen, die Sie weiter anpassen können.",
  "I am": "Ich bin",
  "For example 'Give us a form that will serve as a patient intake form for walk-in clinics'": "Zum Beispiel 'Geben Sie uns ein Formular, das als Anmeldeformular für Patienten in Walk-In-Kliniken dient'.",
  "Enter your prompt here": "Geben Sie hier Ihren Hinweis ein",
  "Would you like to continue?": "Möchten Sie fortfahren?",
  "Restart": "Neustart",
  "I'm here to provide further assistance. Thank you!": "Ich stehe Ihnen gerne für weitere Unterstützung zur Verfügung. Vielen Dank!",
  "Generate Form Design": "Formulardesign generieren",
  "Preview": "Vorschau",
  "Your form is now ready!": "Ihr Formular ist jetzt bereit!",
  "Please tell me all the specifications and requirements you have in mind for your form. After you have finished the description, hit send.": "Bitte geben Sie uns alle Spezifikationen und Anforderungen, die Sie für Ihr Formular im Kopf haben. Nachdem Sie die Beschreibung abgeschlossen haben, drücken Sie die Eingabetaste.",
  "Choose template": "Vorlage auswählen",
  "Search by bundle name": "Nach Bundle-Namen suchen",
  "Save Bundle": "Bundle speichern",
  "Id": "Id",
  "Enable bundling ?": "Bündelung aktivieren?",
  "Select a task in the List.": "Wählen Sie eine Aufgabe in der Liste aus.",
  "Add Date": "Datum hinzufügen",
  "Assign to Me": "Mir zuweisen",
  "Filter count:": "Filteranzahl:",
  "Selected Forms": "Ausgewählte Formulare",
  "Template Used:": "Verwendetes Template:",
  "Select a task from the List.": "Wählen Sie eine Aufgabe aus der Liste aus.",
  "Choose a task from the list; your selection directs your current activity.": "Wählen Sie eine Aufgabe aus der Liste aus; Ihre Auswahl bestimmt Ihre aktuelle Aktivität.",
  "Please change the selected filters to view submissions": "Bitte ändern Sie die ausgewählten Filter, um Einreichungen anzuzeigen",
  "Please change the selected filters to view drafts": "Bitte ändern Sie die ausgewählten Filter, um Entwürfe anzuzeigen",
  "No drafts found": "Keine Entwürfe gefunden",
  "Flow-E helps to build faster": "Flow-E hilft, schneller zu bauen",
  "Flow-E is not available for demonstration in the trial instance. You can experience Flow-E in any of the premium packages of the product": "Flow-E steht in der Testinstanz nicht für eine Demonstration zur Verfügung. Sie können Flow-E in einem der Premium-Pakete des Produkts erleben",
  "Get in touch with formsflow.ai team": "Kontaktieren Sie das formsflow.ai-Team",
  "Premium": "Premium"
};
